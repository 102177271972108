import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { Table,Alert } from "react-bootstrap";
import Loader from "../common/Loader";
import * as _ from "lodash";
import DeleteConfirmation from "../common/DeletePopUp";
import FeatureForm from "./FeatureForm";

function FeatureDetails({ feature, getFeature, getFeatures, featureListLoading,updateFeature,allFeatures,deleteFeature }) {
  let id = useParams();

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (id) {
      getFeature(id.id);
    }
  }, [id]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    resetState();
  };
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [parent, setParent] = useState("");

  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [completionTime, setCompletionTime] = useState("");

  const [currentItem, setCurrentItem] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);

  const handleSubmit = () => {
    const data = {
      name: name,
      description,
      completionTime: completionTime.toString(),
      featureCost: cost.toString(),
      parentFeatureId:parseInt(parent?.id),
      featureImage: null,
    };

    if (currentItem) {
      let id = currentItem.id;
      let dt = { id, ...data };
      updateFeature(dt);
      resetState();
    window.location.reload();
    } 
  };

  const handleEdit = (item) => {
    setShow(true);
    setCurrentItem(item);
    setName(item.name);
    setDescription(item.description);
    setCost(item.cost);
    setParent(feature);
    setCompletionTime(item.completionTime);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  

  const handleDelete = (item) => {
    setDeleteMessage(`Are you sure you want to delete the feature '${item.name}'?`);
    setDisplayConfirmationModal(true)
    setCurrentItem(item)
  };

  const submitDelete = (item) => {
    setDisplayConfirmationModal(false);
    deleteFeature({setDeleteSuccessMessage, ...item})

    resetState()
    getFeatures()
    window.location.reload();

};

  const resetState = () => {
    setDeleteSuccessMessage(null);
    setDisplayConfirmationModal(null);
    setName("");
    setDescription("");
    setCurrentItem(null);
    setCost("");
    setShow(false);
    setCompletionTime("");
  };
  return (
    <div className="container">
      <div className="add-module">
        {/* <Button variant="primary" onClick={handleShow}>
  Add Feature
</Button> */}
        {featureListLoading && <Loader />}
      </div>
      <FeatureForm
        show={show}
        handleClose={handleClose}
        name={name}
        parent={parent}
        setParent={setParent}
        completionTime={completionTime}
        setCompletionTime={setCompletionTime}
        setName={setName}
        description={description}
        setDescription={setDescription}
        handleSubmit={handleSubmit}
        currentItem={currentItem}
        setCost={setCost}
        cost={cost}
        allFeatures= { allFeatures}
      />
 {deleteSuccessMessage && <Alert variant="success" dismissible>{deleteSuccessMessage}</Alert>}

      <h2>{feature?.name}</h2>
      <Table className="card-dummy" striped bordered hover>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Name</th>
            <th>Cost</th>
            <th> completion Time(Weeks)</th>

            {/* <th>Parent Feature</th> */}

            <th>Description</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {feature?.childFeatures?.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              {/* <Link to={`/sub_features/${item.id}`} ><td>{item.name}</td></Link>  */}
              <td>
                {/* <Link to={`/feature/${item.id}`}> */}
                {item.name}
                {/* </Link> */}
              </td>
              <td>{item.cost}</td>
              <td>{item.completionTime}</td>

              {/* <td>{item.parentFeatureId? item.parentFeatureId : "---"}</td> */}

              <td>{item.description}</td>

              <td>
                <button
                  className="action-button"
                  onClick={() => handleEdit(item)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button
                  className="action-button"
                  onClick={() => handleDelete(item)}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DeleteConfirmation showModal={displayConfirmationModal} 
  confirmModal={submitDelete} 
  hideModal={hideConfirmationModal} 
  item = {currentItem}
  
  message={deleteMessage}  
  />
    </div>
  );
}

const mapStateToProps = (state) => {
  const allFeatures = _.get(state.feature, "allFeatures", []);
  const feature = _.get(state.feature, "feature", {});
  const featureListLoading = _.get(state.feature, "featureListLoading", false);

  return {
    allFeatures,
    feature,
    featureListLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeature: (data) =>
    dispatch({ type: "getFeatureSagaCalled", payload: data }),
  updateFeature:(data)=> dispatch({type:"updateFeatureCalled", payload:data}),
  deleteFeature:(data)=> dispatch({type:"deleteFeatureCalled", payload:data}),
  getFeatures: (data) => dispatch({ type: "featureListcalled"}),




});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureDetails);
