import React from 'react'
import NavBar from '../../components/common/navbar/NavBar'
import FeaturesList from '../../components/features/FeaturesList'

function FeatuesListPage() {
  return (
    <>
    <NavBar />
    <FeaturesList />
    </>
  )
}

export default FeatuesListPage