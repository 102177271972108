import React from 'react'
import HeaderWithSidebar from './Header'
import { useState, useEffect } from 'react';
import { Link, useNavigate,useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { Modal } from 'react-bootstrap'
import * as _ from "lodash";
import { ConfirmPopUp } from '../../popUp/PopUpCards';

function NavBar(
  {
    userLogout,
  }
) {

  useEffect(() => {
    window.scrollTo(0, 0);
   
    
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    userLogout()
    handleClose();
  };

  return (
    <>
    <ConfirmPopUp 
    message={"Are you sure you want to log out?"}
    show={show}
    handleClose={handleClose}
    handleLogout = {handleLogout}
    />
   <HeaderWithSidebar logout = {handleShow}/>
   </>
  )
}

const mapStateToProps = (state) => {
  const allModules = _.get(state.module, "allModules", []);

  return { allModules}
};



const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch({ type: "logoutSagaCalled"}),

});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

