import React from "react"
import {useState, useEffect} from "react"
// import Form from 'react-bootstrap/Form';
import ModuleForm from "./ModuleForm"
import {Button, Table, Alert, Tooltip, OverlayTrigger} from "react-bootstrap"
// import { useNavigate, } from 'react-router-dom';
import {connect} from "react-redux"
import * as _ from "lodash"
// import { add } from 'lodash';
import DeleteConfirmation from "../common/DeletePopUp"
import Loader from "../common/Loader"
import ImageUploadModal from "./ImageUploadModal"
import ReactPaginate from "react-paginate"

function ModuleList({
  addModule,
  userInfo,
  getModules,
  allModules,
  deleteModule,
  updateModule,
  createModuleLoading,
  moduleListLoading,
  allFeatures,
  getFeatures,
  addTemplateImage,
  templateImageLoading,
  deleteTemplateImage,
}) {
  // const navigate= useNavigate()

  const [show, setShow] = useState(false)
  const [module, setModule] = useState("")
  const [description, setDescription] = useState("")
  const [features, setFeatures] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const [currentItem, setCurrentItem] = useState(null)
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false)
  const [deleteMessage, setDeleteMessage] = useState(null)
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null)
  const [errorName, setErrorName] = useState(null)
  const [errorDescription, setErrorDescription] = useState(null)
  const [errorFeature, setErrorFeature] = useState(null)
  const [errorFileUpload, setErrorFileUpload] = useState(null)
  const [templateImages, settemplateImages] = useState([])

  const [imageModalshow, setImageModalshow] = useState(false)
  const [currentTemplateId, setCurrentTemplateId] = useState()

  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [currentImage, setCurrentImage] = useState()
  const [currentImageIds, setCurrentImageIds] = useState([])
  const [delteImageLoading, setDelteImageLoading] = useState(false)
  const [add, setAdd] = useState(false)
  const [pages, setpages] = useState(0)
  const [limit, setlimit] = useState(10)
  const [skip, setskip] = useState(0)
  const hideImageConfirmationModal = () => {
    setShowDeletePopUp(false)
  }

  const showDeletePopUpHanlder = item => {
    setShowDeletePopUp(true)
    setCurrentImage(item)
  }

  const handleClose = () => {
    setShow(false)
    resetState()
  }
  const handleShow = () => {
    setShow(true)
    setAdd(true)
  }

  const handleSubmit = () => {
    let featureIds = features.map(item => item.id)
    let currentFeatureIds = currentItem?.features?.map(item => item.feature.id)
    let removeFeatureIds = currentFeatureIds?.filter(
      element => !featureIds.includes(element)
    )
    let data = {
      name: module,
      description,
      featureIds,
      templateImages: selectedFiles,
      removeFeatureIds: removeFeatureIds ? removeFeatureIds : [],
    }

    if (
      module.length <= 0 &&
      description.length <= 0 &&
      featureIds.length <= 0
    ) {
      setErrorName("Please fill *mandatory field")
      setErrorDescription("Please fill *mandatory field")
      setErrorFeature("Please fill *mandatory field")
    } else {
      if (module.length <= 0) {
        setErrorName("Please fill *mandatory field")

        return
      }

      if (description.length <= 0) {
        setErrorDescription("Please fill *mandatory field")

        return
      }

      if (featureIds.length <= 0) {
        setErrorFeature("Please fill *mandatory field")

        return
      }

      if (currentItem) {
        let id = currentItem.id
        data = {id, ...data}
        updateModule(data)
        resetState()
        getModules({
          skip: pages * limit,
          limit,
        })
      } else {
        if (selectedFiles?.length != 0) {
          addModule(data)
          resetState()
        } else {
          setErrorFileUpload("Select alteast 1 image")
        }
      }
    }
  }
  const handleEdit = item => {
    let currentfeatures = item.features.map(ft => ft.feature)
    let CurrentAdminFeatures = currentfeatures.filter(
      tm => tm.category == "Admin"
    )
    setShow(true)
    setCurrentItem(item)
    setAdd(false)
    setCurrentTemplateId(item.id)

    setModule(item.name)
    setDescription(item.description)
    setFeatures(CurrentAdminFeatures)
    settemplateImages(item.templateImages)
    // setSelectedFiles(item.templateImages)
  }

  const handleDelete = item => {
    setDeleteMessage(
      `Are you sure you want to delete the '${item.name}' template?`
    )
    setDisplayConfirmationModal(true)
    setCurrentItem(item)
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false)
  }

  const submitDelete = item => {
    // setDeleteSuccessMessage(`The module '${item.name}' was deleted successfully.`);
    setDisplayConfirmationModal(false)
    deleteModule({setDeleteSuccessMessage, ...item})
    resetState()
    getModules({
      skip: pages * limit,
      limit,
    })
  }

  const handleRemoveFile = (event, index) => {
    event.preventDefault()

    const updatedFiles = [...selectedFiles]
    updatedFiles.splice(index, 1)
    setSelectedFiles(updatedFiles)
    setErrorFileUpload("")
  }

  const handleFileChange = e => {
    setErrorFileUpload("")
    const files = Array.from(e.target.files)
    const allowedTypes = ["image/jpeg", "image/png"]

    const filteredFiles = files.filter(file => allowedTypes.includes(file.type))

    if (filteredFiles.length !== files.length) {
      setErrorFileUpload("Please select files in JPG or PNG format.")
      return
    }

    if (selectedFiles.length + templateImages.length >= 5) {
      setErrorFileUpload("You can add a maximum of five images.")
      return
    }

    const remainingSlots = 5 - selectedFiles.length - templateImages.length
    const newFiles = filteredFiles.slice(0, remainingSlots)
    setSelectedFiles([...selectedFiles, ...newFiles])
  }
  const getCountFromFrontend = () => {
    let counted = 0
    if (allModules?.count > 0) {
      counted = allModules?.count
      return counted
    }
  }

  const pageCount = Math.ceil(allModules.count / limit)
  const handleImageSubmit = () => {
    let data = {
      templateImages: selectedFiles,
      id: currentTemplateId,
      setImageModalshow,
    }
    if (selectedFiles.length > 0) {
      addTemplateImage(data)
    } else {
      alert("Please select the Images")
    }
  }

  // const handleImageDelete=(file)=>{
  //   console.log(file)
  //   let  imageIds = currentImageIds

  //   let data={
  //     imageIds:[imageIds],
  //     id:currentTemplateId
  //   }
  //   deleteTemplateImage(data)

  // }

  const handleImageDelete = async file => {
    let imageIds = [currentImage?.id]
    console.log(currentImage)
    let token = userInfo.accessToken
    hideImageConfirmationModal()

    try {
      const url = `https://builderxapi.sparkrex.com/api/template/templateImage/${currentTemplateId}`
      setDelteImageLoading(true)
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          imageIds: imageIds,
        }),
      })

      if (response.ok) {
        setDeleteSuccessMessage("Images deleted successfully")
        setDelteImageLoading(false)
        handleUploadImagesModalClose()
        window.location.reload()
      } else {
        console.error("Failed to delete images")
      }
    } catch (error) {
      console.error("An error occurred while deleting images:", error)
      alert(error)
      setDelteImageLoading(false)
      hideImageConfirmationModal()
    }
  }

  const handleUploadImagesModalClose = () => {
    setImageModalshow(false)
    setCurrentImageIds([])
    setSelectedFiles([])
  }

  const handleUploadImages = item => {
    settemplateImages(item.templateImages)
    setCurrentTemplateId(item.id)
    setImageModalshow(true)
  }

  const resetState = () => {
    setDeleteSuccessMessage(null)
    setModule("")
    setDescription("")
    setCurrentItem(null)
    setDisplayConfirmationModal(false)
    setDeleteMessage(null)
    setShow(false)
    setFeatures([])
    setSelectedFiles([])
    setErrorDescription("")
    setErrorFeature("")
    setErrorName("")
    setErrorFileUpload("")
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getModules({
      skip: pages * limit,
      limit,
    })
    getFeatures({
      skip: pages * limit,
      limit,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])
  const tooltip = <Tooltip id="tooltip">Upload Images</Tooltip>

  return (
    <>
      <div className="container">
        {(moduleListLoading ||
          createModuleLoading ||
          delteImageLoading ||
          templateImageLoading) && <Loader />}
        <div className="add-module">
          <Button variant="primary" onClick={handleShow}>
            Add Template
          </Button>
        </div>
        <ModuleForm
          show={show}
          handleClose={handleClose}
          module={module}
          add={add}
          setModule={setModule}
          description={description}
          setDescription={setDescription}
          setFeatures={setFeatures}
          features={features}
          handleSubmit={handleSubmit}
          currentItem={currentItem}
          allFeatures={allFeatures}
          errorName={errorName}
          setErrorName={setErrorName}
          errorDescription={errorDescription}
          setErrorDescription={setErrorDescription}
          errorFeature={errorFeature}
          setErrorFeature={setErrorFeature}
          templateImages={templateImages}
          selectedFiles={selectedFiles}
          handleRemoveFile={handleRemoveFile}
          errorFileUpload={errorFileUpload}
          handleFileChange={handleFileChange}
          handleImageSubmit={handleImageSubmit}
          handleImageDelete={handleImageDelete}
          hideConfirmationModal={hideImageConfirmationModal}
          showDeletePopUpHanlder={showDeletePopUpHanlder}
          showDeletePopUp={showDeletePopUp}
          currentImage={currentImage}
          settemplateImages={settemplateImages}
          currentImageIds={currentImageIds}
          setCurrentImageIds={setCurrentImageIds}
          templateImageLoading={templateImageLoading}
        />

        {deleteSuccessMessage && (
          <Alert className="mt-4" variant="success" dismissible>
            {deleteSuccessMessage}
          </Alert>
        )}
        <Table className="card-dummy" striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">S.no</th>
              <th>Name</th>
              <th>Description</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {allModules?.list?.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center" style={{width: "10%"}}>
                  {index + 1 + pages * limit}
                </td>
                <td style={{width: "25%"}}>{item.name}</td>
                <td style={{width: "50%"}}>{item.description}</td>
                <td className="text-center" width={15}>
                  <button
                    className="action-button"
                    onClick={() => handleEdit(item)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button
                    className="action-button "
                    onClick={() => handleDelete(item)}
                  >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                  <OverlayTrigger placement="right" overlay={tooltip}>
                    <button
                      className="action-button"
                      onClick={() => handleUploadImages(item)}
                    >
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="paginationallignment">
          {getCountFromFrontend() > 0 && (
            <div className="paginationsection">
              <div className="paginationtxtholer">
                <span className="noofpagetxt">
                  Showing {pages + 1} From{" "}
                  {Math.ceil(getCountFromFrontend() / limit)}
                </span>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={e => {
                  getModules({
                    skip: e.selected * limit,
                    limit,
                  })
                  setpages(e.selected)
                }}
                forcePage={pages}
                // pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                // marginPagesDisplayed={1} // Show one page before and after current page
                // pageRangeDisplayed={2}
                containerClassName="pagination"
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          item={currentItem}
          message={deleteMessage}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const allModules = _.get(state.module, "allModules", [])
  const createModuleLoading = _.get(state.module, "createModuleLoading", false)
  const moduleListLoading = _.get(state.module, "moduleListLoading", false)
  const allFeatures = _.get(state.feature, "allFeatures", [])
  const templateImageLoading = _.get(
    state.module,
    "templateImageLoading",
    false
  )
  const userInfo = _.get(state.user, "userInfo")

  return {
    allModules,
    createModuleLoading,
    moduleListLoading,
    allFeatures,
    templateImageLoading,
    userInfo,
  }
}

const mapDispatchToProps = dispatch => ({
  addModule: data => dispatch({type: "createModulecalled", payload: data}),
  getModules: data => dispatch({type: "moduleListcalled", payload: data}),
  deleteModule: data => dispatch({type: "deleteModuleCalled", payload: data}),
  updateModule: data => dispatch({type: "updateModuleCalled", payload: data}),
  getFeatures: data => dispatch({type: "featureListcalled", payload: data}),
  addTemplateImage: data =>
    dispatch({type: "createTemplateImageSagaCalled", payload: data}),
  deleteTemplateImage: data =>
    dispatch({type: "deleteTemplateSagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleList)
