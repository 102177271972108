import React from "react"
import {connect} from "react-redux"
import {useEffect, useState} from "react"
import {Button, Table, Alert} from "react-bootstrap"
import * as _ from "lodash"
import DeleteConfirmation from "../common/DeletePopUp"
import Loader from "../common/Loader"
import DomainForm from "./DomainForm"
import CustomPopUp from "../common/CustomPopUp"
import ReactPaginate from "react-paginate"

function DomainsList({
  getModules,
  allModules,
  createModuleLoading,
  moduleListLoading,
  editModuleLoading,
  deleteModuleLoading,
  allFeatures,
  addDomain,
  updateDomain,
  getDomains,
  deleteDomain,
  allDomains,
  domainLoading,
}) {
  useEffect(() => {
    // window.scrollTo(0, 0);
    getModules({
      skip: pages,
      limit,
    })
    getDomains({
      skip: pages,
      limit,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [show, setShow] = useState(false)

  const [domain, setDomain] = useState("")
  const [description, setDescription] = useState("")
  const [templates, setTemplates] = useState([])

  const [currentItem, setCurrentItem] = useState(null)
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false)
  const [deleteMessage, setDeleteMessage] = useState(null)
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null)
  const [errorName, setErrorName] = useState(null)
  const [errorDescription, setErrorDescription] = useState(null)
  const [errorTemplates, setErrorTemplates] = useState(null)
  const [pages, setpages] = useState(0)
  const [limit, setlimit] = useState(10)
  const submitDelete = item => {
    setDisplayConfirmationModal(false)

    deleteDomain({setDeleteSuccessMessage, ...item})
    // getModules();
    resetState()
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false)
  }
  const getCountFromFrontend = () => {
    let counted = 0
    if (allDomains?.count > 0) {
      counted = allDomains?.count
      return counted
    }
  }

  const pageCount = Math.ceil(allDomains.count / limit)
  const handleEdit = item => {
    let currentTemplates = item.templates.map(ft => ft.template)
    setShow(true)
    setCurrentItem(item)
    setDomain(item.name)
    setDescription(item.description)
    setTemplates(currentTemplates)
  }

  const handleSubmit = () => {
    let templateIds = templates?.map(template => parseInt(template.id))
    let data = {
      name: domain,
      description,
      templateIds,
    }
    if (
      domain.length <= 0 &&
      description.length <= 0 &&
      templates.length <= 0
    ) {
      setErrorName("Please fill *mandatory field")
      setErrorDescription("Please fill *mandatory field")
      setErrorTemplates("Please fill *mandatory field")
    } else {
      if (domain.length <= 0) {
        setErrorName("Please fill *mandatory field")

        return
      }

      if (description.length <= 0) {
        setErrorDescription("Please fill *mandatory field")

        return
      }

      if (templates.length <= 0) {
        setErrorTemplates("Please fill *mandatory field")

        return
      }

      if (currentItem) {
        let removetemplateIds = currentItem.templates
          ?.map(item => parseInt(item.template.id))
          .filter(value => !templateIds.includes(value))

        let Updateddata = {
          name: domain,
          description,
          templateIds,
          removetemplateIds: removetemplateIds,
        }
        let id = currentItem.id
        data = {id, ...Updateddata}
        updateDomain(data)
        resetState()
        getDomains({
          skip: pages,
          limit,
        })
      } else {
        addDomain(data)
        resetState()
      }
    }
  }

  const resetState = () => {
    setDeleteSuccessMessage(null)
    setDomain("")
    setDescription("")
    setCurrentItem(null)
    setDisplayConfirmationModal(false)
    setDeleteMessage(null)
    setShow(false)
    setTemplates([])
    setErrorName("")
    setErrorDescription("")
    setErrorTemplates("")
  }

  const handleDelete = item => {
    setDeleteMessage(
      `Are you sure you want to delete the domain '${item.name}'?`
    )
    setDisplayConfirmationModal(true)

    setCurrentItem(item)
  }

  const handleClose = () => {
    setShow(false)
    resetState()
  }

  const handleShow = () => setShow(true)

  // const handleShow = () => setShow(true)

  return (
    <div className="container">
      <div className="add-module">
        <Button variant="primary" onClick={handleShow}>
          Add Domain
        </Button>
      </div>
      <DomainForm
        show={show}
        handleClose={handleClose}
        domain={domain}
        setDomain={setDomain}
        description={description}
        setDescription={setDescription}
        setTemplates={setTemplates}
        templates={templates}
        handleSubmit={handleSubmit}
        currentItem={currentItem}
        allModules={allModules}
        errorName={errorName}
        setErrorName={setErrorName}
        errorDescription={errorDescription}
        setErrorDescription={setErrorDescription}
        errorTemplate={errorTemplates}
        setErrorTemplates={setErrorTemplates}
      />
      {deleteSuccessMessage && (
        <Alert
          style={{color: "red"}}
          className="mt-4"
          variant="success"
          dismissible
        >
          {deleteSuccessMessage}
        </Alert>
      )}
      <Table className="card-dummy" striped bordered hover>
        <thead>
          <tr>
            <th className="text-center">S.no</th>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allDomains?.list?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center"> {index + 1 + pages * limit}</td>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>
                <button
                  className="action-button"
                  onClick={() => handleEdit(item)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button
                  className="action-button"
                  onClick={() => handleDelete(item)}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {domainLoading && <Loader />}
      <div className="paginationallignment">
        {getCountFromFrontend() > 0 && (
          <div className="paginationsection">
            <div className="paginationtxtholer">
              <span className="noofpagetxt">
                Showing {pages + 1} From{" "}
                {Math.ceil(getCountFromFrontend() / limit)}
              </span>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={e => {
                getDomains({
                  skip: e.selected * limit,
                  limit,
                })
                setpages(e.selected)
              }}
              forcePage={pages}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              // marginPagesDisplayed={1} // Show one page before and after current page
              // pageRangeDisplayed={2}
              containerClassName="pagination"
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        item={currentItem}
        message={deleteMessage}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const allModules = _.get(state.module, "allModules", [])
  const createModuleLoading = _.get(state.module, "createModuleLoading", false)
  const moduleListLoading = _.get(state.module, "moduleListLoading", false)
  const editModuleLoading = _.get(state.module, "editModuleLoading", false)
  const deleteModuleLoading = _.get(state.module, "deleteModuleLoading", false)
  const allFeatures = _.get(state.feature, "allFeatures", [])
  const allDomains = _.get(state.domain, "allDomains", [])
  const domainLoading = _.get(state.domain, "domainLoading", undefined)

  return {
    allModules,
    createModuleLoading,
    moduleListLoading,
    editModuleLoading,
    deleteModuleLoading,
    allFeatures,
    allDomains,
    domainLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  getModules: data => dispatch({type: "moduleListcalled", payload: data}),
  getDomains: data => dispatch({type: "getDomainSagaCalled", payload: data}),
  addDomain: data => dispatch({type: "createDomainSagaCalled", payload: data}),

  updateDomain: data =>
    dispatch({type: "updateDomainSagaCalled", payload: data}),
  deleteDomain: data =>
    dispatch({type: "deleteDomainSagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(DomainsList)
