import React from "react";
import { Button, Card, Modal, Spinner,Row,Col } from "react-bootstrap";
import "./popUps.css";
import { Link } from "react-router-dom";

export function FeatureDetailsPopUp(props) {
  return (
    <Card className="feature-popup-card-main">
      <div className="popup-close-btn-container">
        <Button onClick={props.cancel} className="popup-close-btn">
          <i class="fa fa-times" aria-hidden="true"></i>
        </Button>
      </div>
      <Card.Body className="popup-card-body">
        <Card.Title>{props.title}</Card.Title>
        {!props.custom && (
          <Card.Subtitle className="mb-2 text-muted">
            {`from ${props.cost}`}
          </Card.Subtitle>
        )}

        <Card.Text>{props.details}</Card.Text>
        <div className="popup-bg-btn mt-3">
          <Button onClick={props.cancel} variant="primary">
            {props.button_text}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export function SuccessPopUp(props) {
  return (
    <Modal
      className={`submit-success-popup ${props.status}`}
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Card className="success-popup-card-main">
        <div className="popup-close-btn-container">
          <Button onClick={()=>props.handleClose()} className="popup-close-btn">
            <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
        </div>
        <Card.Body className=" popup-card-body text-center d-flex flex-column">
          <Card.Title>{props.message}</Card.Title>

        {/* {props.signup &&
        
          <Row>
            <Col className="redirect-text">
             Click here to.{" "}
              <Link to="/login" className="login-signup">
                Login
              </Link>
            </Col>
          </Row>
        } */}
        {props.signup?
          <div className="popup-bg-btn">
          <Button onClick={()=>props.navigate("/login")} variant="primary">
           Login
          </Button>
        </div>
        :
        <div className="popup-bg-btn">
        <Button onClick={props.handleClose} variant="primary">
          {props.button_text}
        </Button>
      </div>
        
      }
        
        </Card.Body>
      </Card>
    </Modal>
  );
}

export function DeleteConfirmPopUp(props) {
  return (
    <Modal
      className={`submit-success-popup ${props.status}`} //status: alert or success
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Card className="success-popup-card-main">
        <div className="popup-close-btn-container">
          <Button onClick={props.handleClose} className="popup-close-btn">
            <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
        </div>
        <Card.Body className=" popup-card-body text-center d-flex flex-column">
          <Card.Title>Do you want to delete this feature?</Card.Title>
          <div className="delete-popup-bg-btn">
            <Button onClick={props.handleClose} variant="dark">
              Cancel
            </Button>
            <Button
              disabled={props.loading && true}
              onClick={props.deleteHandler}
              variant="danger"
            >
              {props.loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    size="sm"
                    role="status"
                  />
                  <span className="mx-3">Loading...</span>
                </div>
              ) : (
                "Ok"
              )}
            </Button>
          </div>

          {/* <div className='popup-bg-btn'>
    </div> */}
        </Card.Body>
      </Card>
    </Modal>
  );
}


export function ConfirmPopUp(props) {
  return (
    <div className="confirm-popup-container">
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        <div className="custom-modal">
          <p className="confirm-text">{props.message}</p>
          <div className="confirm-buttons">
            <Button variant="primary" className="yes-btn" onClick={props.handleLogout}>
              Yes
            </Button>
            <Button variant="danger" className="no-btn" onClick={props.handleClose}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>

  );
}
