import React from 'react'
import { useState, useEffect } from 'react';
import { Button,Table,Alert} from "react-bootstrap";
import { Link, useNavigate,useParams} from 'react-router-dom';
import { connect } from "react-redux";
import * as _ from "lodash";
import DeleteConfirmation from '../common/DeletePopUp';
import Loader from "../common/Loader"
import SubFeaturesForm from './SubFeaturesForm';

function SubFeaturesList(

  {
    getModules,
    allModules,
    getFeatures,
    allFeatures,
    addFeature,
    getSubFeatues,
    allSubFeatures,
    addSubFeature,
    subFeatureEroor,
    subFeatureLoading,
    updateSubFeature,
    deleteSubFeature,
    subFeatureUpdate,
  }
) {

  const navigate= useNavigate()
  let feature_id = useParams();

  useEffect(() => {
    if(feature_id){
      getSubFeatues(feature_id.id);

    }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
    getModules();
    getFeatures();
    // getSubFeatues();
    //   handleFilter();

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



const [show, setShow] = useState(false);
const handleClose = () => {

  setShow(false);
  resetState()
}
const handleShow = () => setShow(true);
const [name, setName] = useState("");
const [feature, setFeature] = useState("");

const [description, setDescription] = useState("");
const [cost , setCost] = useState("");



const [currentItem, setCurrentItem] = useState(null);
const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
const [deleteMessage, setDeleteMessage] = useState(null);
const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);



const handleSubmit =()=>{
  let data = {
    name:name,
    description,
    featureCost:cost.toString(),
    mainfeatureId:parseInt(feature),
    featureImage:null,
  }


  if (currentItem){
    let id = currentItem.id
    let dt = {id, ...data}
    updateSubFeature(dt)
   resetState()
   getSubFeatues()
   if(!subFeatureLoading){
    navigate(`/`)
   }

  }else{
  
    addSubFeature(data)
    resetState()
  }
}
const handleEdit = (item) => {
  setShow(true)
  setCurrentItem(item);
  setName(item.name)
  setDescription(item.description)
  setCost(item.Cost)

  
}

const handleDelete = (item) => {
  setDeleteMessage(`Are you sure you want to delete the feature '${item.name}'?`);
  setDisplayConfirmationModal(true)
  setCurrentItem(item)
 
}

const hideConfirmationModal = () => {
  setDisplayConfirmationModal(false);
};

const submitDelete = (item) => {
    // setDeleteSuccessMessage(`The module '${item.name}' was deleted successfully.`);
    setDisplayConfirmationModal(false);
    deleteSubFeature(item)
    resetState()
    getFeatures()

};

const resetState =()=>{
  setDeleteSuccessMessage(null);
  setName("")
  setDescription("")
  setCurrentItem(null)
  setCost("")
  setFeature("")
  setShow(false)

  

}



  return (
    <>
    <div className="container">
      <div className='add-module'>
    <Button variant="primary" onClick={handleShow}>
    Add Sub Feature
  </Button>
  {subFeatureLoading && <Loader />}
  </div>
      <SubFeaturesForm
      show={show}
      handleClose={handleClose}
      name={name}
      module={feature}
      setModule= {setFeature}

      setName={setName}
      description={description}
      setDescription={setDescription}
      handleSubmit={handleSubmit}
      currentItem = {currentItem}
      setCost={setCost}
      Cost = {cost}
      allFeatures= {allFeatures}
      />
 {deleteSuccessMessage && <Alert variant="success">{deleteSuccessMessage}</Alert>}
<Table className='card-dummy' striped bordered hover>
      <thead>
        <tr>
          <th>S.no</th>
          <th>Name</th>
          <th>Main Feature</th>

          <th>Cost</th>
          <th>Description</th>

          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {(allSubFeatures?.length) >0? allSubFeatures.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{(allFeatures.find(data=>data.id === item.mainfeatureId))?.name}</td>

            <td>{item.Cost}</td>

            <td>{item.description}</td>


            <td>
              <button className='action-button' onClick={() => handleEdit(item)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
              <button className='action-button' onClick={() => handleDelete(item)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
            </td>
          </tr>
        )): !subFeatureLoading&&  <div className='empty-table'> No Sub Features Found </div>}
      </tbody>
    </Table>
    <Link to ="/features" >Back to Features </Link>
    <DeleteConfirmation showModal={displayConfirmationModal} 
    confirmModal={submitDelete} 
    hideModal={hideConfirmationModal} 
    item = {currentItem}
    
    message={deleteMessage}  
    />


    </div>

</>
  )
}

const mapStateToProps = (state) => {
  const allModules = _.get(state.module, "allModules", []);
  const allFeatures = _.get(state.feature, "allFeatures", []);
  const allSubFeatures = _.get(state.subFeature, "allSubFeatures", []);
  const subFeatureLoading =_.get(state.subFeature, "subFeatureLoading", false);
  const subFeatureEroor = _.get(state.subFeature, "subFeatureEroor", undefined)
  const subFeatureUpdate = _.get(state.feature, "subFeatureUpdate", false)

 


  return { allModules, allFeatures ,allSubFeatures,subFeatureEroor,subFeatureLoading,subFeatureUpdate}
};



const mapDispatchToProps = (dispatch) => ({
  getModules: (data) => dispatch({ type: "moduleListcalled"}),
  getFeatures: (data) => dispatch({ type: "featureListcalled"}),
  addFeature: (data) => dispatch({ type: "createFeatureCalled", payload: data }),

  getSubFeatues:(data)=>dispatch({type:"subFeatureListcalled", payload:data}),

  addSubFeature: (data) => dispatch({ type: "createSubFeatureCalled", payload: data }),
  updateSubFeature: (data) => dispatch({ type: "updateSubFeatureCalled", payload: data }),
  deleteSubFeature: (data) => dispatch({ type: "deleteSubFeatureCalled", payload: data }),





  
  




});

export default connect(mapStateToProps, mapDispatchToProps)(SubFeaturesList);

