import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  featureList: undefined,
  featureListLoading: false,
  featureListError: undefined,
  allFeatures: [],
  feature: undefined,
  featuresArray: [],
  featuresArrayLoading: false,
  featuresArrayError: undefined,
}

const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    reset: state => {
      state.featureList = undefined
      state.featureListLoading = false
      state.featureListError = undefined
      state.allFeatures = []
    },

    listTrigger: (state, action) => {
      state.featureListLoading = true
      state.allFeatures = undefined
      state.featureListError = undefined
    },
    listSuccess: (state, action) => {
      state.featureListLoading = false
      state.allFeatures = action.payload
      state.featureListError = undefined
    },
    listFail: (state, action) => {
      state.featureListLoading = false
      state.allFeatures = undefined
      state.featureListError = action.payload
    },

    featureTrigger: (state, action) => {
      state.featureListLoading = true
      state.feature = undefined
      state.featureListError = undefined
    },
    featureSuccess: (state, action) => {
      state.featureListLoading = false
      state.feature = action.payload
      state.featureListError = undefined
    },
    featureFail: (state, action) => {
      state.featureListLoading = false
      state.feature = undefined
      state.featureListError = action.payload
    },

    createTrigger: (state, action) => {
      state.featureListLoading = true
    },
    createSuccess: (state, action) => {
      state.featureListLoading = false
      // state.allFeatures = [...state.allFeatures, action.payload]
      state.allFeatures = [action.payload]

      state.featureListError = undefined
    },
    createFail: (state, action) => {
      state.featureListLoading = false
      state.featureListError = action.payload
    },

    updateTrigger: (state, action) => {
      state.featureListLoading = true
      state.featureListError = undefined
    },
    updateSuccess: (state, action) => {
      state.featureListLoading = false
      state.allFeatures = [action.payload]
      state.featuresArray = [
        action.payload,
        ...state.featuresArray?.filter(item => item.id !== action.payload.id),
      ]
    },
    updateFail: (state, action) => {
      state.featureListLoading = false
      state.featureListError = action.payload
    },

    deleteTrigger: (state, action) => {
      state.featureListLoading = true
      state.featureListError = undefined
    },
    deleteSuccess: (state, action) => {
      state.featureListLoading = false
      // state.allFeatures = state.allFeatures?.filter(
      //   feature => feature.id !== action.payload.id
      // )
      state.allFeatures = action.payload
    },
    deleteFail: (state, action) => {
      state.featureListLoading = false
      state.featureListError = action.payload
    },

    featuresArrayTrigger: (state, action) => {
      state.featuresArrayLoading = true
      state.featuresArray = undefined
      state.featuresArrayError = undefined
    },
    featuresArraySuccess: (state, action) => {
      state.featuresArrayLoading = false
      state.featuresArray = action.payload
      state.featuresArrayError = undefined
    },
    featuresArrayFail: (state, action) => {
      state.featuresArrayLoading = false
      state.featuresArray = undefined
      state.featuresArrayError = action.payload
    },
  },
})

export default featureSlice.reducer
export const {
  listTrigger,
  listSuccess,
  listFail,
  createTrigger,
  createSuccess,
  createFail,
  updateTrigger,
  updateFail,
  updateSuccess,
  deleteTrigger,
  deleteFail,
  deleteSuccess,
  reset,
  featureFail,
  featureSuccess,
  featureTrigger,
  featuresArrayTrigger,
  featuresArraySuccess,
  featuresArrayFail,
} = featureSlice.actions
