import {call, put} from "redux-saga/effects"
import Api from "../services/ApiCaller"
import {delay} from "redux-saga/effects"
// import { getAccessToken } from "@store/utilities";
import {getAccessToken} from "./utilities"
import {
  listTrigger,
  listSuccess,
  listFail,
  createTrigger,
  createSuccess,
  createFail,
  updateTrigger,
  updateFail,
  updateSuccess,
  deleteTrigger,
  deleteFail,
  deleteSuccess,
  reset,
  featureFail,
  featureSuccess,
  featureTrigger,
  featuresArrayTrigger,
  featuresArraySuccess,
  featuresArrayFail,
} from "./featureredux"

export function* getFeatureSaga(api, {payload}) {
  yield put(featureTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getFeauteById,
      {token, id: payload},
      true
    )
    yield put(featureSuccess(result))
  } catch (e) {
    alert(e)
    yield put(featureFail(e.message))
  }
}

export function* featureListSaga(api, {payload = {}}) {
  yield put(listTrigger())
  const {limit, skip} = payload
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getFeautes,
      {token, skip, limit},
      true
    )
    yield put(listSuccess(result))
    // window.location.reload()
  } catch (e) {
    alert(e)
    yield put(listFail(e.message))
  }
}
export function* createFeatureSaga(api, {payload}) {
  yield put(createTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.createFeature,
      {token, feature: payload},
      true
    )

    yield put(createSuccess(result))
    window.location.reload()
  } catch (e) {
    yield put(createFail(e.message))
  }
}

export function* updateFeatureSaga(api, {payload}) {
  // let {setshowPopup} = payload
  // delete payload["setshowPopup"]
  yield put(updateTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.updateFeature,
      {token, feature: payload},
      true
    )

    yield put(updateSuccess(result))
    // yield put(listTrigger())
    // setshowPopup(`${result?.name} is updated successfully.`)
    window.location.reload()
  } catch (e) {
    yield put(updateFail(e.message))
  }
}

export function* deleteFeatureSaga(api, {payload = {}}) {
  yield put(deleteTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.deleteFeature,
      {token, featureId: payload},
      true
    )
    payload.setDeleteSuccessMessage(
      `The module '${payload.name}' is deleted successfully.`
    )
    // yield put(deleteSuccess(payload))
    window.location.reload()

    // yield put(listSuccess(result2))
  } catch (e) {
    yield put(updateFail(e.message))
  }
}

export function* getFeatureArraySaga(api, {payload}) {
  yield put(featuresArrayTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getAllFeaturesArray,
      {token},
      true
    )
    yield put(featuresArraySuccess(result))
  } catch (e) {
    alert(e)
    yield put(featuresArrayFail(e.message))
  }
}
