import {createSlice} from "@reduxjs/toolkit"

const appSlice = createSlice({
  name: "app",
  initialState: {
    loading: false,
    error: null,
    createFeatureLoading: false,
    features: undefined,
    featuresEroor: undefined,
    getSubmitProjectLoading: false,
    submitProject: [],
    getSubmitProjectError: undefined,
  },
  reducers: {
    createFeatureStart: (state, action) => {
      return {
        ...state,
        createFeatureLoading: true,
        features: undefined,
        featuresEroor: undefined,
      }
    },

    createFeatureSuccess: (state, action) => {
      return {
        ...state,
        createFeatureLoading: false,
        features: action.payload,
        featuresEroor: undefined,
      }
    },

    createFeatureFailure: (state, action) => {
      return {
        ...state,
        createFeatureLoading: false,
        features: undefined,
        featuresEroor: action.payload,
      }
    },

    // ---------------------------------------------Domain------------------------------------------//

    getDomainsStart: (state, action) => {
      return {
        ...state,
        domainLoading: true,

        allDomains: undefined,
        domaintError: undefined,
      }
    },

    getDomainsSuccess: (state, action) => {
      return {
        ...state,

        domainLoading: false,
        allDomains: action.payload,
        domaintError: undefined,
      }
    },

    getDomainsFail: (state, action) => {
      return {
        ...state,
        domainLoading: false,
        allDomains: [],
        domaintError: action.payload,
      }
    },

    createDomainsStart: (state, action) => {
      return {
        ...state,
        domainLoading: true,

        allDomains: undefined,
        domaintError: undefined,
      }
    },

    createDomainsSuccess: (state, action) => {
      // return {
      //   ...state,
      //   domainLoading: false,
      //   allDomains: [...state.allDomains, action.payload],
      //   domaintError: undefined,
      // };
    },

    createDomainsFail: (state, action) => {
      return {
        ...state,
        domainLoading: false,
        allDomains: [],
        domaintError: action.payload,
      }
    },

    updateDomainsStart: (state, action) => {
      return {
        ...state,
        domainLoading: true,

        allDomains: undefined,
        domaintError: undefined,
      }
    },

    updateDomainsSuccess: (state, action) => {
      // return {
      //   ...state,

      //   domainLoading: false,
      //   allDomains: [...state.allDomains, action.payload],
      //   domaintError: undefined,
      // };

      state.domainLoading = false
      const {id, data} = action.payload
      state.allDomains = [
        action.payload,
        ...state.allDomains.filter(feaute => feaute.id !== id),
      ]
      state.domaintError = undefined
    },

    updateDomainsFail: (state, action) => {
      return {
        ...state,
        domainLoading: false,
        allDomains: [],
        domaintError: action.payload,
      }
    },

    deleteDomainsStart: (state, action) => {
      return {
        ...state,
        domainLoading: true,

        allDomains: undefined,
        domaintError: undefined,
      }
    },

    deleteDomainsSuccess: (state, action) => {
      // return {
      //   ...state,

      //   domainLoading: false,
      //   allDomains: [...state.allDomains, action.payload],
      //   domaintError: undefined,
      // };

      state.domainLoading = false
      state.allDomains = [
        action.payload,
        ...state.allDomains.filter(domain => domain.id !== action.payload),
      ]
      state.domaintError = undefined
    },

    deleteDomainsFail: (state, action) => {
      return {
        ...state,
        domainLoading: false,
        allDomains: [],
        domaintError: action.payload,
      }
    },
    // -----------------------------------------Submitted Project----------------------------------------------------//
    getSubmitProjectStart: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: true,

        submitProject: undefined,
        getSubmitProjectError: undefined,
      }
    },

    getSubmitProjectSuccess: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: false,
        submitProject: action.payload,
        getSubmitProjectError: undefined,
      }
    },

    getSubmitProjectFail: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: false,
        submitProject: [],
        getSubmitProjectError: action.payload,
      }
    },
  },
})

export const {
  createFeatureStart,
  createFeatureSuccess,
  createFeatureFailure,
  getSubmitProjectStart,
  getSubmitProjectSuccess,
  getSubmitProjectFail,
} = appSlice.actions
export default appSlice.reducer
