import React from 'react'
import PorjectsList from '../../components/project/PorjectsList'
import NavBar from '../../components/common/navbar/NavBar'
function ProjectPage() {
  return (

    <div>
        <NavBar />

        <PorjectsList />
    </div>
  )
}

export default ProjectPage