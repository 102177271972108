import { call, put } from "redux-saga/effects";
import Api from "../services/ApiCaller";
// import { getAccessToken } from "./utilities";
// import { getToken, setToken } from "./utilities";
// import { useNavigate } from 'react-router-dom';


import { signInStart,
    signInSuccess,
    signInFailure,
    signUpStart,
    signUpSuccess,
    signUpFailure,
    userLogout,
     } from "./userredux";


export function* userLoginSaga(api, { payload }) {

    yield put(signInStart());
    try {
      const { email, password } = payload;
      const result = yield call(
        Api.callServer,
        api.login,
        { email: email, password: password },
        true
      );
      if (result.role === "Admin"){
        yield put(signInSuccess(result))

      }else{
      yield put(signInFailure("You are not authorized."));
      // alert("You are not authorized.")
      payload.setLoginError("You are not authorized.")


      }

      // const token = yield getAccessToken();
      
    } catch (err) {
      if (err.message.includes("User doest not exist") || (err.message.includes("No User Account is Available"))){

        // alert("Account with this email address doesn't exist")
        payload.setLoginError("Account with this email address doesn't exist")
        
      }else if (err.message.includes("Invalid Password")){
        payload.setLoginError("The password you have entered is incorrect")

        // alert("The password you have entered is incorrect.")
      }
      else{
        // alert(err.message)
        payload.setLoginError(err.message)

      }
      yield put(signInFailure());

    }
  }

  export function* logoutSaga(api, { payload = {} }) {
    try {
      yield put(userLogout());
      if (payload) {
        payload.replace("/login");
      }
    } catch (error) {}
  }

  export function* userSignUpSaga(api, { payload }) {
    yield put(signUpStart());
    
    try {
      let data = {
        email:payload.email,
        password:payload.password,
        role:"Admin",
        loginType:payload.loginType,
        sendVerification:false,
          
      }
      const result = yield call(Api.callServer, api.signUpUser, data, true);
      yield put(signUpSuccess(result));
      payload.setSuccessPopUpShow(true)
      payload.resetState()
    } catch (e) {
      yield put(signUpFailure(e.message));
      if(e.message.includes("password must be longer than or equal to 8 characters")){
        payload.setSignUpError("Password must be longer than or equal to 8 characters")
      // alert("Password must be longer than or equal to 8 characters")
      }else if( e.message.includes("Duplicate entry")){
        payload.setSignUpError("The email address you have entered is already in use.")

        // alert("The email address you have entered is already in use.")
      }else if (e.message.includes("EMAIL IS ALREADY USED")){
        // alert(e.message)
        payload.setSignUpError("The email you have entered is already exist")

      }else{
        payload.setSignUpError(e.message)

      }
    }
    //   alert(e.message)
    //   yield put(signUpFailure(e.message));
    // }
  }
