import React from "react"
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap"
import Select from "react-select"

function DomainForm(props) {
  const {
    show,
    handleClose,
    allModules,
    domain,
    setDomain,
    description,
    setDescription,
    handleSubmit,
    currentItem,
    templates,
    setTemplates,
    errorName,
    setErrorName,
    errorDescription,
    setErrorDescription,
    errorTemplate,
  } = props

  //    change the data values to pass the Select option
  const options = allModules?.list?.map(item => ({
    value: item.id,
    label: item.name,
    description: item.description,
  }))

  // const defaultValue = templates.map(item => ({
  //   value: item.id,
  //   label: item.name,
  //   description: item.description,
  // }))

  const defaultValue = templates.map(item => ({
    value: item.id,
    label: item.name,
    description: item.description,
  }))

  const handleChange = selectedOption => {
    const optionsWithId = selectedOption.map(({value, label, ...rest}) => ({
      id: value,
      name: label,
      ...rest,
    }))
    setTemplates(optionsWithId)
    props.setErrorTemplates("")
  }

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!currentItem ? "Add Domain" : "Update Domain"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Form
                //   onSubmit={handleSubmit}
                >
                  {/* <FormGroup className="form-input">
                    <label>Email</label>
                    <InputField
                      placeholder="Enter email"
                      type="text"
                      // value={email}
                      classname="form-field"
                      // onChange={(event) => {
                      //   setEmail(event.target.value);
                      // }}
                    />
                  </FormGroup> */}
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Name*</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorName(null)
                        setDomain(e.target.value)
                      }}
                      value={domain}
                      placeholder="Module Name"
                      required
                    />
                    {errorName && (
                      <div className="text-danger">{errorName}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Description*</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorDescription(null)
                        setDescription(e.target.value)
                      }}
                      as="textarea"
                      rows={3}
                      value={description}
                      placeholder="Module Description"
                      required
                    />
                    {errorDescription && (
                      <div className="text-danger">{errorDescription}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Templates*</Form.Label>
                    <Select
                      value={defaultValue}
                      options={options}
                      isMulti
                      onChange={handleChange}
                      required
                    />
                    {errorTemplate && (
                      <div className="text-danger">{errorTemplate}</div>
                    )}

                    {/* <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Templates</Form.Label>
                    <Select
                      value={defaultValue}
                      options={options}
                      isMulti
                      onChange={handleChange}
                    />
                    {errorTemplate && (
                      <div className="text-danger">{errorTemplate}</div>
                    )} */}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {currentItem ? (
            <Button variant="success" onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default DomainForm
