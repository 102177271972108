import React from 'react'
import DomainsList from '../components/domain/DomainsList'
import NavBar from '../components/common/navbar/NavBar'

function DomainPage() {
  return (
    <div>
        <NavBar />
        <DomainsList/>
    </div>
  )
}

export default DomainPage