import {call, put} from "redux-saga/effects"
import Api from "../services/ApiCaller"
import {getAccessToken} from "./utilities"
import {
  listTrigger,
  listSuccess,
  listFail,
  createTrigger,
  createSuccess,
  createFail,
  updateTrigger,
  updateFail,
  updateSuccess,
  deleteTrigger,
  deleteFail,
  deleteSuccess,
  reset,
} from "./domainredux"

export function* getDomainSaga(api, {payload = {}}) {
  yield put(listTrigger())
  try {
    const token = yield getAccessToken()
    const {limit, skip} = payload
    const result = yield call(
      Api.callServer,
      api.getDomains,
      {token, limit, skip},
      true
    )
    yield put(listSuccess(result))
  } catch (e) {
    alert(e.message)
    yield put(listFail(e.message))
  }
}

export function* createDomainSaga(api, {payload}) {
  yield put(createTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.createDomain,
      {token, domain: payload},
      true
    )
    yield put(createSuccess(result))
    window.location.reload()
  } catch (e) {
    yield put(createFail(e.message))
  }
}

export function* updateDomainSaga(api, {payload}) {
  yield put(updateTrigger())
  try {
    const token = yield getAccessToken()
    if (payload.removetemplateIds?.length > 0) {
      delete payload["templateIds"]
      const result = yield call(
        Api.callServer,
        api.updateDomainRemoveTemplate,
        {token, domain: payload},
        true
      )
      yield put(updateSuccess(result))
    }
    if (payload.templateIds?.length > 0) {
      // delete payload['removetemplateIds']
      const result = yield call(
        Api.callServer,
        api.updateDomain,
        {token, domain: payload},
        true
      )
      yield put(updateSuccess(result))
    }
    window.location.reload()
  } catch (e) {
    yield put(updateFail(e.message))
  }
}

export function* deleteDomainSaga(api, {payload}) {
  yield put(deleteTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.deleteDomain,
      {token, domain: payload},
      true
    )
    payload.setDeleteSuccessMessage(
      `The domain '${payload.name}' is deleted successfully.`
    )
    yield put(deleteSuccess(result))
    window.location.reload()
  } catch (e) {
    alert(e.message)
    yield put(deleteFail(e.message))
  }
}

// export function* deleteDomainSaga(api, {payload}) {
//   yield put(deleteTrigger())
//   try {
//     const token = yield getAccessToken()
//     const result = yield call(
//       Api.callServer,
//       api.deleteDomain,
//       {token, domain: payload},
//       true
//     )
//     payload.setDeleteSuccessMessage(
//       `The domain '${payload.name}' is deleted successfully.`
//     )
//     yield put(deleteSuccess(payload))
//   } catch (e) {
//     alert(e.message)
//     yield put(deleteFail(e.message))
//   }
// }
