import React from 'react'
import FeatureDetails from '../../components/features/FeatureDetails'
import NavBar from '../../components/common/navbar/NavBar'

function FeatureDetailsPage() {
  return (
    <>
    <NavBar/>
    <FeatureDetails />
    </>
  )
}

export default FeatureDetailsPage