import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  moduleList: undefined,
  createModuleLoading: false,
  createModuleError: "",
  moduleListLoading: false,
  moduleListError: undefined,
  editModuleLoading: false,
  editModuleError: undefined,
  deleteModuleLoading: false,
  allModules: [],
  templateImages: [],
  templateImageError: undefined,
  templateImageLoading: false,
}

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    reset: state => {
      state.moduleList = undefined
      state.createModuleLoading = false
      state.createModuleError = ""
      state.moduleListLoading = false
      state.moduleListError = undefined
      state.allModules = []
    },
    createSuccess: (state, action) => {
      state.createModuleLoading = false
      state.allModules = [action.payload]
      state.createModuleError = undefined
    },
    createTrigger: (state, action) => {
      state.createModuleLoading = true
    },
    editSuccess: (state, action) => {
      state.moduleListLoading = false
      state.editModuleLoading = false
      // const { id} = action.payload;
      // state.allModules = [...state.allModules.filter(item=> item.id !==id ),action.payload]

      state.allModules = [action.payload]
    },
    createFail: (state, action) => {
      state.createModuleError = action.payload
      state.createModuleLoading = false
    },

    editTrigger: (state, action) => {
      state.moduleListLoading = true
      state.moduleListError = undefined
    },
    editFail: (state, action) => {
      state.editModuleError = action.payload
      state.moduleListLoading = false
    },

    listTrigger: (state, action) => {
      state.moduleListLoading = true
      state.allModules = undefined
      state.moduleListError = undefined
    },
    listSuccess: (state, action) => {
      state.moduleListLoading = false
      state.allModules = action.payload
      state.moduleListError = undefined
    },
    listFail: (state, action) => {
      state.moduleListLoading = false
      state.allModules = undefined
      state.moduleListError = action.payload
    },
    deleteTrigger: (state, action) => {
      state.moduleListLoading = true
      state.moduleListError = undefined
    },
    deleteSuccess: (state, action) => {
      state.moduleListLoading = false
      // const {id} = action.payload
      // state.allModules = state.allModules.filter(item => item.id !== id)
      state.allModules = action.payload

      state.moduleListError = undefined
    },

    deleteFail: (state, action) => {
      state.moduleListLoading = false
      state.moduleListError = action.payload
    },

    createTemplateImageTrigger: (state, action) => {
      state.templateImageLoading = true
      state.templateImageError = undefined
    },

    createTemplateImageSuccess: (state, action) => {
      state.templateImageLoading = false
      // state.templateImages = [...state.templateImages, action.payload];
      state.templateImageError = undefined
    },

    createTemplateImageFail: (state, action) => {
      state.templateImageLoading = false
      state.templateImageError = action.payload
    },

    deleteTemplateImageTrigger: (state, action) => {
      state.templateImageLoading = true
      state.templateImageError = undefined
    },

    deleteTemplateImageSuccess: (state, action) => {
      state.templateImageLoading = false
      const {id} = action.payload
      // state.templateImages = [...state.templateImages.filter(item=> item.id !==id ),action.payload]

      state.templateImageError = undefined
    },

    deleteTemplateImageFail: (state, action) => {
      state.templateImageLoading = false
      state.templateImageError = action.payload
    },
  },
})

export default moduleSlice.reducer
export const {
  createFail,
  createSuccess,
  createTrigger,
  editFail,
  editSuccess,
  editTrigger,
  deleteSuccess,
  deleteFail,
  deleteTrigger,
  listTrigger,
  listSuccess,
  listFail,
  createTemplateImageTrigger,
  createTemplateImageSuccess,
  createTemplateImageFail,
  deleteTemplateImageTrigger,
  deleteTemplateImageSuccess,
  deleteTemplateImageFail,
  reset,
} = moduleSlice.actions
