import {
  Navigate,
  // Outlet
} from "react-router-dom";

import {useSelector } from "react-redux";

// we can protect the route with outlet method or child method

export function PrivateRoute({ children }) {
  const auth = useSelector((state) => state.user);
    let isAuthenticated = auth && auth.auth;
  return isAuthenticated ? children : <Navigate to="/login" />
}

// const PrivateRoute = ({ children }) => {
//   const auth = useSelector((state) => state.user);
//   let isAuthenticated = auth && auth.auth;

//   return isAuthenticated ?<Outlet /> : <Navigate to="/login" />
      
  
// };


export function Protected({ children }) {
  const auth = useSelector((state) => state.user);
    let isAuthenticated = auth && auth.auth;
  return !isAuthenticated ? children : <Navigate to="/" />
}
