import React from 'react'
import NavBar from '../components/common/navbar/NavBar'
import "../assets/css/homePage.css"

function HomePage() {
  return (
    <div>
    <NavBar />
    <div className='home-page-main'>
      Welcome to the Builder<span className='home-page-heading'>X</span> Admin
    </div>
    </div>


  )
}

export default HomePage