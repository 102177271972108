import React from 'react'
import SubFeaturesList from '../../components/subFeatures/SubFeaturesList'
import NavBar from '../../components/common/navbar/NavBar'


function SubFeaturePage() {
  return (
    <div>
        <NavBar/>
        <SubFeaturesList/>
    </div>
  )
}

export default SubFeaturePage