import React, {useEffect} from "react"
import {Container, Row, Col, Form, Button} from "react-bootstrap"
// import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal"

function FeatureForm(props) {
  // const {
  //   show,
  //   handleClose,
  //   name,
  //   parent,
  //   setParent,
  //   setName,
  //   setCost,
  //   allFeatures,
  //   completionTime,
  //   setCompletionTime,
  //   description,
  //   setDescription,
  //   handleSubmit,
  //   currentItem,
  //   cost,
  //   errorFeatureCost,
  //   setErrorFeatureCost,
  //   errorFeatureTime,
  //   setErrorFeatureTime,
  //   errorFeatureDescription,
  //   setErrorFeatureDescription,
  //   errorFeatureName,
  //   setErrorFeatureName,
  // } = props

  const {
    show,
    handleClose,
    name,
    parent,
    setParent,
    setName,
    setCost,
    allFeatures,
    completionTime,
    setCompletionTime,
    allFeaturesArray,
    description,
    setDescription,
    handleSubmit,
    currentItem,
    cost,
    errorFeatureCost,
    setErrorFeatureCost,
    errorFeatureTime,
    setErrorFeatureTime,
    errorFeatureDescription,
    setErrorFeatureDescription,
    errorFeatureName,
    setErrorFeatureName,
  } = props

  const handleChange = selected_id => {
    const feature = allFeatures?.list?.find(item => item.id === selected_id)
    setParent(feature)
  }

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Feature Name* </Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorFeatureName(null)
                        setName(e.target.value)
                      }}
                      value={name}
                      placeholder="Feature Name"
                    />
                    {errorFeatureName && (
                      <div className="text-danger">{errorFeatureName}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Feature Cost* </Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorFeatureCost(null)
                        if (e.target.value >= 0) {
                          setCost(e.target.value)
                        }
                      }}
                      type="number"
                      rows={3}
                      value={cost}
                      placeholder="Feature Cost "
                    />
                    {errorFeatureCost && (
                      <div className="text-danger">{errorFeatureCost}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Completion Time* </Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorFeatureTime(null)
                        if (e.target.value >= 0) {
                          setCompletionTime(e.target.value)
                        }
                      }}
                      type="number"
                      rows={3}
                      value={completionTime}
                      placeholder="Completion Time "
                    />
                    {errorFeatureTime && (
                      <div className="text-danger">{errorFeatureTime}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="form-input">
                    <Form.Label htmlFor="">Parent Feature</Form.Label>

                    <Form.Select
                      aria-label="Parent Feature"
                      // onChange={(event) => {
                      //   setParent(event.target.value);}}
                      onChange={e => handleChange(e.target.value)}
                      value={parent?.id}
                      as="select"
                      style={{minWidth: "100%"}}
                    >
                      <option>Open this select menu</option>

                      {allFeatures?.list?.map((item, key) => (
                        <option value={item?.id} key={key}>
                          {(item?.name).substring(0, 50)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Description*</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorFeatureDescription(null)
                        setDescription(e.target.value)
                      }}
                      as="textarea"
                      rows={3}
                      value={description}
                      placeholder="Feature Description"
                    />
                    {errorFeatureDescription && (
                      <div className="text-danger">
                        {errorFeatureDescription}
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {currentItem ? (
            <Button variant="success" onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default FeatureForm
