import React from 'react'
import ModuleList from '../../components/module/ModuleList'
import NavBar from '../../components/common/navbar/NavBar'


function ModuleListPage() {


  return (
    <>
    <NavBar />
   <ModuleList />

  
   </>
  )
}

export default ModuleListPage