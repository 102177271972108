import React from 'react'
import ModuleDetails from '../../components/module/ModuleDetails'
function ModuleDetailPage() {
  return (
    <div>ModuleDetailPage
        <ModuleDetails />
    </div>
  )
}

export default ModuleDetailPage