// import "@components/loader/LoderStyle.css";
import React from "react";
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
    // <div className="load">
    // <div className="loader">

    // </div>
    // </div>
    <div className="loading-spinner">
    <div className="loading-spinner-overlay" />
    <div className="loading-spinner-content">
      <Spinner className="custom-loading-spinner" animation="border" variant="primary"  style={{ fontSize: '2rem' }} />
    </div>
  </div>
  );
}

export default Loader;
