import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  domainLoading: false,
  domainError: undefined,
  allDomains: [],
}

const featureSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    reset: state => {
      state.domainLoading = false
      state.domainError = undefined
      state.allDomains = []
    },

    listTrigger: (state, action) => {
      state.domainLoading = true
      state.allDomains = undefined
      state.domainError = undefined
    },
    listSuccess: (state, action) => {
      state.domainLoading = false
      state.allDomains = action.payload
      state.domainError = undefined
    },
    listFail: (state, action) => {
      state.domainLoading = false
      state.allDomains = undefined
      state.domainError = action.payload
    },

    createTrigger: (state, action) => {
      state.domainLoading = true
    },
    createSuccess: (state, action) => {
      state.domainLoading = false
      // state.allDomains = [...state.allDomains, action.payload]
      state.allDomains = [action.payload]

      state.domainError = undefined
    },
    createFail: (state, action) => {
      state.domainLoading = false
      state.domainError = action.payload
    },

    updateTrigger: (state, action) => {
      state.domainLoading = true
      state.domainError = undefined
    },
    updateSuccess: (state, action) => {
      state.domainLoading = false
      // const {id, data} = action.payload
      // state.allDomains = [
      //   ...state.allDomains.filter(feaute => feaute.id !== id),
      //   action.payload,
      // ]
      state.allDomains = [action.payload]
    },
    updateFail: (state, action) => {
      state.domainLoading = false
      state.domainError = action.payload
    },

    deleteTrigger: (state, action) => {
      state.domainLoading = true
      state.domainError = undefined
    },
    deleteSuccess: (state, action) => {
      state.domainLoading = false
      // state.allDomains = state.allDomains.filter(
      //   item => item.id !== action.payload.id
      // )
      state.allDomains = action.payload
    },
    deleteFail: (state, action) => {
      state.domainLoading = false
      state.domainError = action.payload
    },
  },
})

export default featureSlice.reducer
export const {
  listTrigger,
  listSuccess,
  listFail,
  createTrigger,
  createSuccess,
  createFail,
  updateTrigger,
  updateFail,
  updateSuccess,
  deleteTrigger,
  deleteFail,
  deleteSuccess,
  reset,
} = featureSlice.actions
