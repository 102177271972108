import apisauce from "apisauce"

// const baseUrl = process.env.REACT_APP_APILINK;
const baseUrl = "https://builderxapi.sparkrex.com/api"

const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  })

  const login = ({email, password}) => {
    return apis.post("auth/signin", {email: email, password: password})
  }

  const getMyProfile = ({accessToken}) => {
    return apis.get(
      "users/my-profile",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }
  const signUpUser = data => {
    delete data["navigate"]
    return apis.post("auth/signUp", data)
    // { email, password, loginType,sendVerification,roleType}
    // {email: email, password: password, loginType:loginType,sendVerification:sendVerification,roleType:roleType}
  }

  const requestPasswordReset = ({email}) => {
    return apis.post("auth/request-password-reset", {email})
  }

  const userProfile = ({token}) => {
    return apis.get(
      `/users/my-profile`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const changePassword = ({token, data}) => {
    return apis.post(`/auth/change-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const createModule = data => {
    let token = data.token
    delete data["token"]
    return apis.post(`/template`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    })
  }

  const getModules = data => {
    return apis.get(
      `/template?skip=${data.skip}&limit=${data.limit}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const updateModule = data => {
    let token = data.token
    delete data["token"]
    delete data.module["templateImages"]

    return apis.patch(`/template/${data.module.id}`, data.module, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const deleteModule = data => {
    return apis.delete(
      `/template/${data.moduleId.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getFeautes = data => {
    return apis.get(
      `/features?custom=fale&limit=${data.limit}&skip=${data.skip}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getFeauteById = data => {
    return apis.get(
      `/features/${data.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const createFeature = data => {
    let token = data.token
    delete data["token"]
    let item = data.feature

    return apis.post(`/features`, item, {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const updateFeature = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(`/features/${data.feature.id}`, data.feature, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const deleteFeature = data => {
    return apis.delete(
      `/features/${data.featureId.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getSubFeautes = data => {
    let id = parseInt(data.id)

    return apis.get(
      `/features/subfeatures/${id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const createSubFeature = data => {
    let token = data.token
    delete data["token"]
    let item = data.feature

    return apis.post(`/features/subfeatures`, item, {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const updateSubFeature = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(
      `/features/subfeatures/${data.feature.id}`,
      data.feature,
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    )
  }

  const deleteSubFeature = data => {
    return apis.delete(
      `/features/subfeatures/${data.subFeature.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const createDomain = data => {
    let token = data.token
    delete data["token"]
    let item = data.domain

    return apis.post(`/domain`, item, {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getDomains = data => {
    let token = data.token
    return apis.get(
      `/domain?features=true&subFeatures=true&skip=${data.skip}&limit=${data.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const updateDomain = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(`/domain/${data.domain.id}`, data.domain, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const updateDomainRemoveTemplate = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(`/domain/${data.domain.id}?remove=true`, data.domain, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const deleteDomain = data => {
    return apis.delete(
      `/domain/${data.domain.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const addTemplateImages = data => {
    const formData = new FormData()
    data.templateImages.forEach(file => {
      formData.append("images", file)
    })
    let token = data.token
    delete data["token"]

    return apis.post(`/template/templateImage/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    })
  }

  const deleteTemplateImage = data => {
    let imageIds = data.imageIds
    let id = parseInt(data.id)
    return apis.delete(`/template/templateImage/${id}`, imageIds, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        Accept: "Application/json",
      },
    })
  }

  const getSubmittedProject = data => {
    let token = data.token
    return apis.get(
      `/user/projects?&skip=${data.skip}&limit=${data.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const getAllFeaturesArray = data => {
    let token = data.token
    return apis.get(
      `/features/names`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  return {
    login,
    getMyProfile,
    signUpUser,
    requestPasswordReset,
    userProfile,
    changePassword,
    createModule,
    getModules,
    getFeautes,
    getFeauteById,
    createFeature,
    deleteModule,
    updateModule,
    updateFeature,
    deleteFeature,
    getSubFeautes,
    createSubFeature,
    deleteSubFeature,
    updateSubFeature,
    getDomains,
    updateDomain,
    deleteDomain,
    createDomain,
    updateDomainRemoveTemplate,
    addTemplateImages,
    deleteTemplateImage,
    getSubmittedProject,
    getAllFeaturesArray,
  }
}

export default {
  create,
}
