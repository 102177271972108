import { call, put } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import { getAccessToken } from "./utilities";

import { 

  listTrigger,
  listSuccess,
  listFail,
  createTrigger,
  createSuccess,
  createFail,
  updateTrigger,
  updateFail,
  updateSuccess,
  deleteTrigger,
  deleteFail,
  deleteSuccess,
  // reset,
 } from "./subfeatureredux";

  export function* subFeatureListSaga(api, { payload }) {
    yield put(listTrigger());
    try {
      const token = yield getAccessToken()
      const result = yield call(
        Api.callServer,
        api.getSubFeautes,
        { token, id:payload},
        true
      );
      yield put(listSuccess(result));
    } catch (e) {
      alert(e.message)
      yield put(listFail(e.message));
    }
  }

  export function* createSubFeatureSaga(api, { payload }) {
    yield put(createTrigger());
    try {
      const token = yield getAccessToken()
      const result = yield call(
        Api.callServer,
        api.createSubFeature,
        { token, feature: payload },
        true
      );
      yield put(createSuccess(result));
    } catch (e) {
      yield put(createFail(e.message));
    }
  }
  

  export function* updateSubFeatureSaga(api, { payload }) {
    yield put(updateTrigger());
    try {
      const token = yield getAccessToken()
      const result = yield call(
        Api.callServer,
        api.updateSubFeature,
        { token, feature: payload },
        true
      );
      yield put(updateSuccess(result));
    } catch (e) {
      yield put(updateFail(e.message));
    }
  }

  export function* deleteSubFeatureSaga(api, { payload }) {
    yield put(deleteTrigger());

    try {
      const token = yield getAccessToken()
      const result = yield call(
        Api.callServer,
        api.deleteSubFeature,
        { token, subFeature: payload },
        true
      );
      yield put(deleteSuccess(result));
    } catch (e) {
      yield put(deleteFail(e.message));
    }
  }
  
  