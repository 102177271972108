import React from "react"
import {useState, useEffect} from "react"
// import Form from 'react-bootstrap/Form';
import {Button, Table, Alert} from "react-bootstrap"
import {Link, useNavigate} from "react-router-dom"
import {connect} from "react-redux"
import * as _ from "lodash"
import DeleteConfirmation from "../common/DeletePopUp"
import FeatureForm from "./FeatureForm"
import Loader from "../common/Loader"
import ReactPaginate from "react-paginate"
function FeatureList({
  getModules,
  getFeatures,
  allFeatures,
  addFeature,
  featureListLoading,
  updateFeature,
  deleteFeature,
  getAllFeaturesArray,
  allFeaturesArray,
}) {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
    resetState()
  }
  const handleShow = () => setShow(true)
  const [name, setName] = useState("")
  const [parent, setParent] = useState("")

  const [description, setDescription] = useState("")
  const [cost, setCost] = useState("")
  const [completionTime, setCompletionTime] = useState("")

  const [currentItem, setCurrentItem] = useState(null)
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false)
  const [deleteMessage, setDeleteMessage] = useState(null)
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null)
  const [showPopup, setshowPopup] = useState("")
  const [errorFeatureName, setErrorFeatureName] = useState(null)
  const [errorFeatureDescription, setErrorFeatureDescription] = useState(null)
  const [errorFeatureCost, setErrorFeatureCost] = useState(null)
  const [errorFeatureTime, setErrorFeatureTime] = useState(null)
  // const [skip, setSkip] = useState(0)

  // const handleSubmit =()=>{
  //   const data = {
  //     name:name,
  //     description,
  //     completionTime:completionTime.toString(),
  //     featureCost:cost.toString(),
  //     parentFeatureId:parseInt(parent?.id),
  //     featureImage:null,
  //   }
  //   if (name.length<=0 && description.length <= 0 && cost.length <= 0 && completionTime.length <= 0){
  //     setErrorFeatureName("Please fill *mandatory field");
  //     setErrorFeatureDescription("Please fill *mandatory field");
  //     setErrorFeatureCost("Please fill *mandatory field");
  //     setErrorFeatureTime("Please fill *mandatory field");

  //   }else{

  //     if (name.length <= 0) {
  //       setErrorFeatureName("Please fill *mandatory field");

  //       return;
  //     }

  //     if (description.length <= 0) {
  //       setErrorFeatureDescription("Please fill *mandatory field");

  //       return;
  //     }

  //     if (cost.length <= 0) {
  //       setErrorFeatureCost("Please fill *mandatory field");

  //       return;
  //     }
  //     if (completionTime.length <= 0) {
  //       setErrorFeatureTime("Please fill *mandatory field");

  //       return;
  //     }
  //   if (currentItem){
  //     let id = currentItem.id
  //     let dt = {id,setshowPopup, ...data}
  //     updateFeature(dt)
  //    resetState()
  //    getFeatures()

  //   }else{
  //     addFeature(data)
  //     resetState()
  //   }
  // }
  // }
  // const handleEdit = (item) => {
  //   setShow(true)
  //   setCurrentItem(item);
  //   setName(item.name)
  //   setDescription(item.description)
  //   setCost(item.cost)
  //   setParent(item.parentFeature)
  //   setCompletionTime(item.completionTime)
  // }
  //   const [description, setDescription] = useState("")
  //   const [cost, setCost] = useState("")
  //   const [completionTime, setCompletionTime] = useState("")

  //   const [currentItem, setCurrentItem] = useState(null)
  //   const [displayConfirmationModal, setDisplayConfirmationModal] =
  //     useState(false)
  //   const [deleteMessage, setDeleteMessage] = useState(null)
  //   const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null)
  //   const [errorFeatureName, setErrorFeatureName] = useState(null)
  //   const [errorFeatureDescription, setErrorFeatureDescription] = useState(null)
  //   const [errorFeatureCost, setErrorFeatureCost] = useState(null)
  //   const [errorFeatureTime, setErrorFeatureTime] = useState(null)
  const [pages, setpages] = useState(0)
  const [limit, setlimit] = useState(10)

  const handleSubmit = () => {
    const data = {
      name: name,
      description,
      completionTime: completionTime.toString(),
      featureCost: cost.toString(),
      parentFeatureId: parseInt(parent?.id),
      featureImage: null,
    }
    if (
      name.length <= 0 &&
      description.length <= 0 &&
      cost.length <= 0 &&
      completionTime.length <= 0
    ) {
      setErrorFeatureName("Please fill *mandatory field")
      setErrorFeatureDescription("Please fill *mandatory field")
      setErrorFeatureCost("Please fill *mandatory field")
      setErrorFeatureTime("Please fill *mandatory field")
    } else {
      if (name.length <= 0) {
        setErrorFeatureName("Please fill *mandatory field")

        return
      }

      if (description.length <= 0) {
        setErrorFeatureDescription("Please fill *mandatory field")

        return
      }

      if (cost.length <= 0) {
        setErrorFeatureCost("Please fill *mandatory field")

        return
      }
      if (completionTime.length <= 0) {
        setErrorFeatureTime("Please fill *mandatory field")

        return
      }
      if (currentItem) {
        let id = currentItem.id
        let dt = {id, ...data}
        updateFeature(dt)

        getFeatures({
          skip: pages * limit,
          limit,
        })
        resetState()
      } else {
        addFeature(data)
        resetState()
      }
    }
  }
  const handleEdit = item => {
    setShow(true)
    setCurrentItem(item)
    setName(item.name)
    setDescription(item.description)
    setCost(item.cost)
    setParent(item.parentFeatureId)
    setCompletionTime(item.completionTime)
  }

  const handleDelete = item => {
    setDeleteMessage(
      `Are you sure you want to delete the feature '${item.name}'?`
    )
    setDisplayConfirmationModal(true)
    setCurrentItem(item)
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false)
  }
  const getCountFromFrontend = () => {
    let counted = 0
    if (allFeatures?.count > 0) {
      counted = allFeatures?.count
      return counted
    }
  }

  const pageCount = Math.ceil(allFeatures.count / limit)
  const submitDelete = item => {
    // setDeleteSuccessMessage(`The module '${item.name}' is deleted successfully.`);
    setDisplayConfirmationModal(false)
    deleteFeature({setDeleteSuccessMessage, ...item})
    resetState()
    getFeatures({
      skip: pages * limit,
      limit,
    })
  }

  // const resetState =()=>{
  //   setDeleteSuccessMessage(null);
  //   setDisplayConfirmationModal(null)
  //   setName("")
  //   setDescription("")
  //   setCurrentItem(null)
  //   setCost("")
  //   setShow(false)
  //   setCompletionTime("")
  //   setErrorFeatureDescription("")
  //   setErrorFeatureCost("")
  //   setErrorFeatureName("")
  //   setErrorFeatureTime("")

  // }

  const resetState = () => {
    setDeleteSuccessMessage(null)
    setDisplayConfirmationModal(null)
    setName("")
    setDescription("")
    setCurrentItem(null)
    setCost("")
    setShow(false)
    setCompletionTime("")
    // setpages(0)
    setErrorFeatureCost("")
    setErrorFeatureName("")
    setErrorFeatureTime("")
  }
  useEffect(() => {
    // window.scrollTo(0, 0);
    // getCountFromFrontend()

    setpages(0)
    getModules({
      skip: pages,
      limit,
    })
    getFeatures({
      skip: pages,
      limit,
    })
  }, [])
  // useEffect(() => {
  //   getModules({
  //     skip: pages * limit,
  //     limit,
  //   })
  //   getFeatures({
  //     skip: pages * limit,
  //     limit,
  //   })
  //   getAllFeaturesArray()
  console.log(allFeatures?.list, "allfeatures")
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  return (
    <>
      <div className="container">
        <div className="add-module">
          <Button variant="primary" onClick={handleShow}>
            Add Feature
          </Button>
          {featureListLoading && <Loader />}
        </div>
        <FeatureForm
          show={show}
          handleClose={handleClose}
          name={name}
          parent={parent}
          setParent={setParent}
          completionTime={completionTime}
          setCompletionTime={setCompletionTime}
          setName={setName}
          description={description}
          setDescription={setDescription}
          handleSubmit={handleSubmit}
          currentItem={currentItem}
          setCost={setCost}
          cost={cost}
          allFeatures={allFeatures}
          errorFeatureName={errorFeatureName}
          setErrorFeatureName={setErrorFeatureName}
          errorFeatureDescription={errorFeatureDescription}
          setErrorFeatureDescription={setErrorFeatureDescription}
          errorFeatureCost={errorFeatureCost}
          setErrorFeatureCost={setErrorFeatureCost}
          errorFeatureTime={errorFeatureTime}
          setErrorFeatureTime={setErrorFeatureTime}
          allFeaturesArray={allFeaturesArray}
        />
        {showPopup && (
          <Alert className="mt-4" variant="success" dismissible>
            {showPopup}
          </Alert>
        )}

        {deleteSuccessMessage && (
          <Alert className="mt-4" variant="success" dismissible>
            {deleteSuccessMessage}
          </Alert>
        )}
        <Table className="card-dummy" striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">S.no</th>
              <th>Name</th>
              <th>Cost</th>
              {/* <th>Parent Feature</th> */}

              <th>Description</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allFeatures?.list?.map(
              (item, index) =>
                item.category === "Admin" && (
                  <tr key={item?.id}>
                    <td className="text-center" style={{width: "10%"}}>
                      {index + 1 + pages * limit}
                    </td>
                    {/* <Link to={`/sub_features/${item.id}`} ><td>{item.name}</td></Link>  */}
                    <td>
                      {item.childFeatures?.length > 0 ? (
                        <Link to={`/feature/${item.id}`}>{item.name}</Link>
                      ) : (
                        item.name
                      )}
                    </td>
                    <td>{item.cost}</td>
                    {/* <td>{item.parentFeatureId? item.parentFeatureId : "---"}</td> */}

                    {/* <Link to={`/sub_features/${item.id}`} ><td>{item.name}</td></Link>  */}
                    <td>
                      {item.childFeatures?.length > 0 ? (
                        <Link to={`/feature/${item.id}`}>{item.name}</Link>
                      ) : (
                        item.name
                      )}
                    </td>
                    <td>{item.cost}</td>
                    <td>
                      {item.parentFeatureId ? item.parentFeatureId : "---"}
                    </td>

                    <td>{item.description}</td>

                    <td>
                      <button
                        className="action-button"
                        onClick={() => handleEdit(item)}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button
                        className="action-button"
                        onClick={() => handleDelete(item)}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
        <div className="paginationallignment">
          {getCountFromFrontend() > 0 && (
            <div className="paginationsection">
              <div className="paginationtxtholer">
                <span className="noofpagetxt">
                  Showing {pages + 1} From{" "}
                  {Math.ceil(getCountFromFrontend() / limit)}
                </span>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={e => {
                  getFeatures({
                    skip: e.selected * limit,
                    limit,
                  })
                  setpages(e.selected)
                }}
                forcePage={pages}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                // marginPagesDisplayed={1} // Show one page before and after current page
                // pageRangeDisplayed={2}
                containerClassName="pagination"
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          item={currentItem}
          message={deleteMessage}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const allFeatures = _.get(state.feature, "allFeatures", [])
  const featureListLoading = _.get(state.feature, "featureListLoading", false)
  const allFeaturesArray = _.get(state.feature, "featuresArray")

  return {allFeatures, featureListLoading, allFeaturesArray}
}

// const mapDispatchToProps = dispatch => ({
//   getModules: data => dispatch({type: "moduleListcalled", payload: data}),
//   getFeatures: data => dispatch({type: "featureListcalled", payload: data}),
//   addFeature: data => dispatch({type: "createFeatureCalled", payload: data}),
//   updateFeature: data => dispatch({type: "updateFeatureCalled", payload: data}),
//   deleteFeature: data => dispatch({type: "deleteFeatureCalled", payload: data}),
// })

const mapDispatchToProps = dispatch => ({
  getModules: data => dispatch({type: "moduleListcalled", payload: data}),
  getFeatures: data => dispatch({type: "featureListcalled", payload: data}),
  addFeature: data => dispatch({type: "createFeatureCalled", payload: data}),
  updateFeature: data => dispatch({type: "updateFeatureCalled", payload: data}),
  deleteFeature: data => dispatch({type: "deleteFeatureCalled", payload: data}),
  getAllFeaturesArray: data =>
    dispatch({type: "getFeatureArraySagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeatureList)
