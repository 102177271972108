import {call, put} from "redux-saga/effects"
import Api from "../services/ApiCaller"
import {getAccessToken} from "./utilities"
import {
  createFeatureStart,
  createFeatureFailure,
  createFeatureSuccess,
  getSubmitProjectStart,
  getSubmitProjectSuccess,
  getSubmitProjectFail,
} from "./appredux"

// export function* createFeatureSaga(api, { payload }) {
//   yield put(createFeatureStart());
//   try {
//     const result = yield call(
//       Api.callServer,
//       api.operatorSignUp,
//       { ...payload },
//       true
//     );
//     yield put(createFeatureSuccess(result));
//     openSuccessPopup();
//   } catch (err) {
//     yield put(createFeatureFailure(err.message));
//   }
// }

//   export function* operatorSignUpRequestSaga(api, { payload }) {
//   yield put(operatorSignUpStart());
//   const { openSuccessPopup } = payload;
//   delete payload["openSuccessPopup"];
//   try {
//     const result = yield call(
//       Api.callServer,
//       api.operatorSignUp,
//       { ...payload },
//       true
//     );
//     yield put(operatorSignUpSuccess(result));
//     openSuccessPopup();
//   } catch (err) {
//     yield put(operatorSignUpFailure(err.message));
//   }
// }

export function* getsubmittedProjectSaga(api, {payload = {}}) {
  yield put(getSubmitProjectStart())
  try {
    const token = yield getAccessToken()
    const {limit, skip} = payload
    const result = yield call(
      Api.callServer,
      api.getSubmittedProject,
      {token, skip, limit},
      true
    )
    result?.list?.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn))

    yield put(getSubmitProjectSuccess(result))
  } catch (e) {
    yield put(getSubmitProjectFail(e.message))

    alert(e.message)
  }
}
