import { all, takeLatest } from "redux-saga/effects";
import API from "./../services/Api";

// import {
//   getEventCategorySaga,
//   getEventsCardSaga,
// } from "./appsaga";

import { userLoginSaga, userSignUpSaga, logoutSaga } from "./usersaga";
// import { createFeatureSaga } from "./appsaga";
import { createModuleSaga,moduleListSaga,deleteModuleSaga,updateModuleSaga,createTemplateImageSaga,deleteTemplateSaga } from "./modulesaga";
import { createFeatureSaga, featureListSaga,updateFeatureSaga,deleteFeatureSaga,getFeatureSaga, getFeatureArraySaga} from "./featuresaga";
import { createSubFeatureSaga,subFeatureListSaga,updateSubFeatureSaga,deleteSubFeatureSaga } from "./subFeatureSaga";
import { getDomainSaga,createDomainSaga,updateDomainSaga,deleteDomainSaga } from "./domainsaga";
import { getsubmittedProjectSaga } from "./appsaga";

const api = API.create();
export default function* root() {
  yield all([
    // takeLatest("getEventCardsSagaCalled", getEventsCardSaga, api),
    // takeLatest("getEventCategorySagaCalled", getEventCategorySaga, api),
    takeLatest("userSignInCalled", userLoginSaga, api),
    takeLatest("userSignupcalled", userSignUpSaga, api),
    takeLatest("logoutSagaCalled", logoutSaga, api),


    takeLatest("createModulecalled", createModuleSaga, api),
    takeLatest("deleteModuleCalled", deleteModuleSaga, api),
    takeLatest("moduleListcalled", moduleListSaga, api),
    takeLatest("updateModuleCalled", updateModuleSaga, api),
    takeLatest("createTemplateImageSagaCalled", createTemplateImageSaga, api),
    takeLatest("deleteTemplateSagaCalled", deleteTemplateSaga, api),

    takeLatest("getsubmittedProjectSagaCalled", getsubmittedProjectSaga, api),


    



    takeLatest("featureListcalled", featureListSaga, api),
    takeLatest("createFeatureCalled",createFeatureSaga,api),
    takeLatest("updateFeatureCalled",updateFeatureSaga,api),
    takeLatest("deleteFeatureCalled",deleteFeatureSaga,api),
    takeLatest("getFeatureArraySagaCalled",getFeatureArraySaga,api),

    


    takeLatest("subFeatureListcalled", subFeatureListSaga, api),
    takeLatest("createSubFeatureCalled",createSubFeatureSaga,api),
    takeLatest("updateSubFeatureCalled",updateSubFeatureSaga,api),
    takeLatest("deleteSubFeatureCalled",deleteSubFeatureSaga,api),

    takeLatest("getDomainSagaCalled",getDomainSaga,api),
    takeLatest("createDomainSagaCalled",createDomainSaga,api),
    takeLatest("updateDomainSagaCalled",updateDomainSaga,api),
    takeLatest("deleteDomainSagaCalled",deleteDomainSaga,api),

    takeLatest("getFeatureSagaCalled", getFeatureSaga, api)




    

   
  ]);
}
