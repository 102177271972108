import logo from './logo.svg';
import './App.css';
import { Provider, useSelector } from "react-redux";
// import store from './store';
import { Nav, Navbar } from 'react-bootstrap';

import AppRoutes from './routes/AppRoutes';
import { PersistGate } from "redux-persist/lib/integration/react";
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Navigate,
} from "react-router-dom";

// import { persistor, store } from "@store";
import {store, persistor} from './store';
import React, { lazy, Suspense } from "react";







function App() {
  return (
       <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ErrorBoundary>
    <Router>
    <AppRoutes />
  </Router>
  </ErrorBoundary>
      </PersistGate>
  </Provider>

  );
}

export default App;
