import React, { useState } from 'react';
import {useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import img from "../../../assets/images/avatar.png"
const HeaderWithSidebar = (props) => {

  const auth = useSelector((state) => state.user);
    let isAuthenticated = auth && auth.auth;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="container-fluid main p-0">
      <nav className="main-header navbar navbar-expand-lg navbar-light bg-light">
      <button
          className="navbar nav-button"
          type="button"
          onClick={toggleSidebar}
        >
          {/* <span className="navbar-toggler-icon" ></span> */}
          
          {isSidebarOpen?<i className="fa fa-times fa-2x" ></i> :<i className="fa fa-bars fa-2x" aria-hidden="true"></i>}
        
        
        </button>
        {/* <Link className="navbar-brand" to="#">
          Builder 
        </Link>

        <Link className="nav-link" to="#">
          Builder 
        </Link> */}
        <ul className="navbar-nav align-items-center">
      <li className="nav-item active">
        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
      </li>

      <li className="nav-item active">
        <Link className="nav-link" to="/modules">Templates<span className="sr-only"></span></Link>
      </li>

    
      <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src={img} alt="Avatar" className="avatar"/>
        </Link>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="#">Profile</Link>
          {/* <Link className="dropdown-item" to="#">Another action</Link> */}
          <div className="dropdown-divider"></div>
          {isAuthenticated?<Link onClick={() => props.logout()} className="dropdown-item" >Logout</Link>:<Link className="dropdown-item" to="login">Login</Link>}
          
        </div>
      </li>
    </ul>
      </nav>
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="nav-link active" to="/">
                Home
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="#">
                About
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link"  to ="/modules">Templates</Link>
           
            </li>
            <li className="nav-item">
              <Link className="nav-link"  to ="/features"> Features</Link>

              {/* <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Features
        </Link>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/features">Main Features</Link>
          <Link className="dropdown-item" to="/sub_features">Sub Features</Link>
        </div>
      </li> */}
              <Link className="nav-link"  to ="/domain">Domain</Link>

           
            </li> 
            <li className="nav-item">
              <Link className="nav-link"  to ="/projects">Projects</Link>
           
            </li>
               <li className="nav-item">
              {isAuthenticated?<Link className="nav-link" onClick={() => props.logout()}> Logout</Link>:<Link className="nav-link"  to ="/login"> Login</Link>}
              
           
            </li>
          </ul>
        </div>
      )}
      <div className="container mt-4">
      </div>
    </div>

  );
};

export default HeaderWithSidebar