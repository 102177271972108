import React from 'react'
// import HomePage from '../pages/HomePage';
import {Routes,Route} from "react-router-dom";


import LoginPage from '../pages/LoginPage';
import HomePage from '../pages/HomePage';
import FeatuesListPage from '../pages/Features/FeatuesListPage';
import FeatureDetailsPage from '../pages/Features/FeatureDetailsPage';
import ModuleListPage from '../pages/Module/ModuleListPage';
import ModuleDetailPage from '../pages/Module/ModuleDetailPage';
import SignUpPagae from '../pages/SignUpPagae';
import SubFeaturePage from '../pages/SubFeatures/SubFeaturePage';
// import PrivateRoute from './privateRoute';
import {PrivateRoute, Protected} from "./privateRoute"
import DomainPage from '../pages/DomainPage';
import ProjectPage from '../pages/Project/ProjectPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';


function AppRoutes() {
  return (
    <div>
    <Routes>

      {/* use Outlet method  */}
      {/* <Route path="/modules" element={<PrivateRoute />}>
        <Route path='' element={<ModuleListPage />} />
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route path='' element={<HomePage />} />
      </Route>

      <Route path="/features" element={<PrivateRoute />}>
        <Route path='' element={<FeatureDetailsPage />} />
      </Route> */}

        <Route path="/login" element={<Protected> <LoginPage/></Protected>}></Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>



      {/* Child Method  */}
      
        <Route path="/" element={<PrivateRoute><HomePage/></PrivateRoute>}></Route>
        <Route path="/modules" element={<PrivateRoute><ModuleListPage/></PrivateRoute>}></Route>
        <Route path="/features" element={<PrivateRoute><FeatuesListPage/></PrivateRoute>}></Route>
        <Route path="/sub_features/:id" element={<PrivateRoute><SubFeaturePage/></PrivateRoute>}></Route>

        <Route path="/domain" element={<PrivateRoute><DomainPage/></PrivateRoute>}></Route>
        <Route path="/projects" element={<PrivateRoute><ProjectPage/></PrivateRoute>}></Route>





      
      <Route path="/feature/:id" element={<FeatureDetailsPage />} />
      <Route path="/module/:id" element={<ModuleDetailPage />} />
      <Route path="/signup" element={<SignUpPagae />} />

      {/* <Route path="/" element={<HomePage />} exact /> */}




     
    </Routes>
    
  </div>
  )
}

export default AppRoutes