import React from 'react'
import { Container, Row, Col, Form,Button} from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';



function SubFeaturesForm
(props) {

  const {show,handleClose, name,module,setModule, setName,setCost,allFeatures, description, setDescription,handleSubmit,currentItem,Cost} = props


  return (
    <Container>
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Add </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="form-div">
      <Row>
        <Col lg={12}>
              <Form 
              >
      
          <Form.Group className="mb-3">
          <Form.Label htmlFor="">Sub Feature Name </Form.Label>
          <Form.Control onChange={(e)=>setName(e.target.value)} value={name}  placeholder="Feature Name" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="">Feature Cost </Form.Label>
          {/* <Form.Control.Feedback type="invalid">invalid</Form.Control.Feedback> */}
          <Form.Control 
          onChange={(e)=>setCost(e.target.value)}
          type="number" rows={3} value={Cost}  placeholder="Feature Cost " />
        </Form.Group>

        <Form.Group cclassName="form-input">
        <Form.Label htmlFor="">Main Feature</Form.Label>

        <Form.Select aria-label="Module"
          onChange={(event) => {
            setModule(event.target.value);}}value={module}  
            as="select">
              <option>Open this select menu</option>

              {allFeatures.map((item,key)=>
              <option value={item.id} key={key}>{item.name}</option>
              )}
              </Form.Select>
              </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="">Description</Form.Label>
          <Form.Control 
          onChange={(e)=>setDescription(e.target.value)}
          as="textarea" rows={3} value={description}  placeholder="Feature Description" />
        </Form.Group>
        
              </Form>
        </Col>
      </Row>
    </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      {currentItem?<Button variant="success" onClick={handleSubmit}>
       Update
      </Button>:<Button variant="primary" onClick={handleSubmit}>
       Add
      </Button> }
      
    </Modal.Footer>
  </Modal>
  </Container>
  )
}

export default SubFeaturesForm
