import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    signInLoading: false,
    auth: false,
    userInfo: null,

    signedIn: undefined,
    signInStatus: false,
    signInError: undefined,
    userDetailsLoading: false,
    userDetails: undefined,
    userDetailsError: undefined,
    signUpLoading: undefined,
    signedUp: undefined,
    signUpError: undefined,
    forgotPasswordLoading: false,
    forgotPassword: undefined,
    forgotPasswordError: undefined,

    

    // changeCounter: 0,
  },
  reducers: {
    resetUserReduxOnMount: (state, action) => {
      return {
        ...state,
        signInLoading: false,

        signInError: undefined,
        userDetailsLoading: false,

        userDetailsError: undefined,
        signUpLoading: undefined,

        signUpError: undefined,
        forgotPasswordLoading: false,

        forgotPasswordError: undefined,

        userProfileLoading: false,

        userProfileError: undefined,

      };
    },
    resetChangeCounter: (state, action) => {
      return {
        ...state,
        changeCounter: 0,
      };
    },
    changeCounterSuccess: (state, action) => {
      return {
        ...state,
        changeCounter: action.payload,
      };
    },
    signInStart: (state, action) => {
      return {
        ...state,
        signInLoading: true,
        signedIn: undefined,
        signInStatus: false,
        signInError: undefined,
      };
    },
    signInSuccess: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signedIn: action.payload,
        signInStatus: true,
        signInError: undefined,
        auth: true,
        userInfo: { ...action.payload },
      };
    },
    signInFailure: (state, action) => {

      return {
        ...state,
        signInLoading: false,
        signedIn: undefined,
        signInStatus: false,
        signInError: "Invalid login credentials",
      };
    },

    // resetSignInState: (state, action) => {
    //   return {
    //     ...state,
    //     signInLoading: false,
    //     signInError: undefined,
    //     // passGoogleAccessTokenSuccess: undefined,
    //     passGoogleAccessTokenError: undefined,
    //   };
    // },

    setUserDetailsStart: (state, action) => {
      return {
        ...state,
        userDetailsLoading: true,
        userDetails: undefined,
        userDetailsError: undefined,
      };
    },

    setUserDetailsSuccess: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: action.payload,
        userDetailsError: undefined,
      };
    },

    setUserDetailsFailure: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: undefined,
        userDetailsError: action.payload,
      };
    },
    signUpStart: (state, action) => {
      return {
        ...state,
        signUpLoading: true,
        signedUp: undefined,
        signUpError: undefined,
      };
    },
    signUpSuccess: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: action.payload,
        signUpError: undefined,
        
      };
    },
    signUpFailure: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: undefined,
        signUpError: action.payload,
      };
    },
    resetSignUp: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: undefined,
        signUpError: undefined,
      };
    },
    forgotPasswordStart: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPassword: undefined,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: action.payload,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordFailure: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: undefined,
        forgotPasswordError: action.payload,
      };
    },

    resetForgotPasswordState: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: undefined,
        forgotPasswordError: undefined,
      };
    },
    
    changePasswordStart: (state, action) => {
        return {
          ...state,
          changePasswordLoading: action.payload,
        };
      },
  
      changePasswordSuccess: (state, action) => {
        return {
          ...state,
          changePassword: action.payload,
        };
      },
  
      changePasswordFailure: (state, action) => {
        return {
          ...state,
          changePasswordError: action.payload,
        };
      },
    userLogout: (state, action) => {
        return { 
          userInfo: null,
          auth:false,

        };
      },
  
}
});

export default userSlice.reducer;
export const {
  signInStart,
  signInSuccess,
  signInFailure,

  signUpStart,
  signUpSuccess,
  signUpFailure,
 
  setUserDetailsStart,
  setUserDetailsSuccess,
  setUserDetailsFailure,

  userLogout,

  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,

  resetForgotPasswordState,

  changePasswordSuccess,
  changePasswordStart,
  changePasswordFailure,

  resetChangeCounter,
  resetUserReduxOnMount,
  
} = userSlice.actions;
