import { configureStore} from "@reduxjs/toolkit";

import createSagaMiddleware from 'redux-saga';
import { combineReducers } from "redux";
import app from './appredux'
import module from './moduleredux'
import user from './userredux'
import feature from './featureredux'
import subFeature from './subfeatureredux'
import domain from "./domainredux"

// import rootReducer from './reducers';
import rootSaga from './sagaIndex';
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user", "app", "module", "feature","domain"],
};

const reducer = combineReducers({
    app,
    user,
    module,
    feature,
    subFeature,
    domain,
  });

  const persistedReducer = persistReducer(persistConfig, reducer);
let sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: [
//     ...getDefaultMiddleware({
//       thunk: false,
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
//     sagaMiddleware,
//   ],
// });
const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store)

export { persistor, store };
