import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  FormGroup,
  Button,
  Dropdown,
  DropdownToggle,
  FormControl,
} from "react-bootstrap"
// import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal"
// import Multiselect from 'react-bootstrap-multiselect';
import Select from "react-select"
import ImageUploadModal from "./ImageUploadModal"

function ModuleForm(props) {
  const {
    show,
    handleClose,
    allFeatures,
    module,
    setModule,
    errorName,
    setErrorName,
    errorDescription,
    setErrorDescription,
    errorFeature,
    setErrorFeature,
    description,
    setDescription,
    handleSubmit,
    currentItem,
    features,
    setFeatures,
    templateImages,
    selectedFiles,
    handleRemoveFile,
    errorFileUpload,
    handleFileChange,
    handleImageSubmit,
    handleImageDelete,
    hideConfirmationModal,
    showDeletePopUpHanlder,
    showDeletePopUp,
    currentImage,
    settemplateImages,
    currentImageIds,
    setCurrentImageIds,
    templateImageLoading,
    add,
  } = props

  // change the data values to pass the Select option
  const options = allFeatures?.list?.map(item => ({
    value: item.id,
    label: item.name,
    description: item.description,
  }))

  const defaultValue = features.map(item => ({
    value: item.id,
    label: item.name,
    description: item.description,
  }))

  const handleChange = selectedOption => {
    const optionsWithId = selectedOption.map(({value, label, ...rest}) => ({
      id: value,
      name: label,
      ...rest,
    }))
    setFeatures(optionsWithId)
  }

  return (
    <Container>
      {/* <Button variant="primary" onClick={handleShow}>
    Launch demo modal
  </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{add ? "Add" : "Edit"} Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Form
                  onSubmit={e => e.preventDefault()}

                  //   onSubmit={handleSubmit}
                >
                  {/* <FormGroup className="form-input">
                  <label>Email</label>
                  <InputField
                    placeholder="Enter email"
                    type="text"
                    // value={email}
                    classname="form-field"
                    // onChange={(event) => {
                    //   setEmail(event.target.value);
                    // }}
                  />
                </FormGroup> */}
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Name*</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorName(null)
                        setModule(e.target.value)
                      }}
                      value={module}
                      placeholder="Module Name"
                    />
                    {errorName && (
                      <div className="text-danger">{errorName}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Description*</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setErrorDescription(null)
                        setDescription(e.target.value)
                      }}
                      as="textarea"
                      rows={3}
                      value={description}
                      placeholder="Module Description"
                    />
                    {errorDescription && (
                      <div className="text-danger">{errorDescription}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="">Features*</Form.Label>
                    <Select
                      value={defaultValue}
                      options={options}
                      isMulti
                      onChange={handleChange}
                    />
                    {errorFeature && (
                      <div className="text-danger">{errorFeature}</div>
                    )}
                  </Form.Group>
                  <ImageUploadModal
                    show={true}
                    templateImages={templateImages}
                    selectedFiles={selectedFiles}
                    handleRemoveFile={handleRemoveFile}
                    errorFileUpload={errorFileUpload}
                    handleFileChange={handleFileChange}
                    handleImageSubmit={handleImageSubmit}
                    handleImageDelete={handleImageDelete}
                    hideConfirmationModal={hideConfirmationModal}
                    showDeletePopUpHanlder={showDeletePopUpHanlder}
                    showDeletePopUp={showDeletePopUp}
                    currentImage={currentImage}
                    settemplateImages={settemplateImages}
                    currentImageIds={currentImageIds}
                    setCurrentImageIds={setCurrentImageIds}
                    templateImageLoading={templateImageLoading}
                    add={add}
                  />
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {currentItem ? (
            <Button variant="success" onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default ModuleForm
