import {call, put} from "redux-saga/effects"
import Api from "../services/ApiCaller"
import {getAccessToken} from "./utilities"
import {
  createFail,
  createSuccess,
  createTrigger,
  editFail,
  editSuccess,
  editTrigger,
  deleteSuccess,
  deleteTrigger,
  deleteFail,
  listTrigger,
  listSuccess,
  listFail,
  createTemplateImageTrigger,
  createTemplateImageSuccess,
  createTemplateImageFail,
  deleteTemplateImageTrigger,
  deleteTemplateImageSuccess,
  deleteTemplateImageFail,
  // reset,
} from "./moduleredux"

export function* createModuleSaga(api, {payload = {}}) {
  yield put(createTrigger())
  try {
    const token = yield getAccessToken()

    let result = yield call(
      Api.callServer,
      api.createModule,
      {token, ...payload},
      true
    )
    yield put(createSuccess(result))

    let result2 = yield call(
      Api.callServer,
      api.addTemplateImages,
      {token, ...payload, id: result?.id},
      true
    )
    console.log(result2)

    yield put(createTemplateImageSuccess(result2))
    window.location.reload()
  } catch (e) {
    yield put(createFail(e))
    alert(e.message)
  }
}

export function* createTemplateImageSaga(api, {payload = {}}) {
  yield put(createTemplateImageTrigger())

  // delete payload["setImageModalshow"]

  try {
    const token = yield getAccessToken()

    let result = yield call(
      Api.callServer,
      api.addTemplateImages,
      {token, ...payload},
      true
    )
    yield put(createTemplateImageSuccess(result))
    // payload.setImageModalshow(false)
    // window.location.reload()
  } catch (e) {
    yield put(createTemplateImageFail(e))
    alert(e.message)
  }
}

export function* moduleListSaga(api, {payload = {}}) {
  yield put(listTrigger())
  try {
    const token = yield getAccessToken()
    const {limit, skip} = payload
    const result = yield call(
      Api.callServer,
      api.getModules,
      {token, limit, skip},
      true
    )
    yield put(listSuccess(result))
  } catch (e) {
    alert(e)
    yield put(listFail(e.message))
  }
}

export function* updateModuleSaga(api, {payload}) {
  yield put(editTrigger())
  try {
    const token = yield getAccessToken()
    if (payload?.templateImages?.length !== 0) {
      let result2 = yield call(
        Api.callServer,
        api.addTemplateImages,
        {token, ...payload, id: payload?.id},
        true
      )
    }

    const result = yield call(
      Api.callServer,
      api.updateModule,
      {token, module: payload},
      true
    )
    yield put(editSuccess(result))

    window.location.reload()
  } catch (e) {
    yield put(editFail(e.message))
  }
}

export function* deleteModuleSaga(api, {payload}) {
  yield put(deleteTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.deleteModule,
      {token, moduleId: payload},
      true
    )
    payload.setDeleteSuccessMessage(
      `The '${payload.name}' template is deleted successfully.`
    )
    yield put(deleteSuccess(payload))
    window.location.reload()
  } catch (e) {
    yield put(deleteFail(e.message))
  }
}

export function* deleteTemplateSaga(api, {payload}) {
  yield put(deleteTemplateImageTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.deleteTemplateImage,
      {token, ...payload},
      true
    )
    // payload.setDeleteSuccessMessage(`The '${payload.name}' template is deleted successfully.`);
    yield put(deleteTemplateImageSuccess(payload))
    const result2 = yield call(Api.callServer, api.getModules, {token}, true)
    yield put(listSuccess(result2))
  } catch (e) {
    yield put(deleteTemplateImageFail(e.message))
    alert(e.message)
  }
}
