import React from "react";
import { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Image,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import DeleteConfirmation from "../common/DeletePopUp";
import { useEffect } from "react";

function ImageUploadModal(props) {
  const {
    show,
    handleClose,
    templateImages,
    selectedFiles,
    handleRemoveFile,
    errorFileUpload,
    handleFileChange,
    handleImageSubmit,
    handleImageDelete,
    hideConfirmationModal,
    showDeletePopUpHanlder,
    showDeletePopUp,
    currentImage,
    settemplateImages,
    currentImageIds,
    setCurrentImageIds,
    templateImageLoading,
    add,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const removeTemplateImage = (e, index) => {
    e.preventDefault();
    setCurrentImageIds([...currentImageIds, templateImages[index].id]);
    const updatedFiles = [...templateImages];
    updatedFiles.splice(index, 1);
    settemplateImages(updatedFiles);
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setShowModal(false);
  };

  return (
    <>
      <DeleteConfirmation
        showModal={showDeletePopUp}
        confirmModal={handleImageDelete}
        hideModal={hideConfirmationModal}
        item={currentImage}
        message={"Are you sure you want to delete this item?"}
      />

      <Modal size={"lg"} centered show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title >Ima</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{ padding: "60px 10px" }}>
          <img
            src={selectedImage}
            alt="Full-Screen Image"
            className="full-screen-image"
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        size={"lg"}
        show={showModal || showDeletePopUp === true ? false : show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Template Images</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3"> */}
      <div className="form-div">
        <Row>
          <Col lg={12}>
            {templateImages && templateImages?.length >= 5 ? (
              <></>
            ) : (
              <Form
                style={{ width: "90%" }}
                onSubmit={(e) => e.preventDefault()}
              >
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Upload Images</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpg, .png"
                    multiple
                    onChange={handleFileChange}
                  />
                  {errorFileUpload && (
                    <div className="text-danger">{errorFileUpload}</div>
                  )}
                </Form.Group>

                {selectedFiles.length > 0 && (
                  <Row>
                    {selectedFiles.map((file, index) => (
                      <Col key={index} sm={6} md={4} lg={3}>
                        <Image
                          className="theme-image-preview"
                          src={URL.createObjectURL(file)}
                          alt="Preview"
                          thumbnail
                        />
                        <button
                          className="action-button theme-image-preview-remove"
                          onClick={(e) => handleRemoveFile(e, index)}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form>
            )}
          </Col>
        </Row>

        {!add && (
          <Table className="card-dummy" striped bordered hover>
            <thead>
              <tr>
                <th>S.no</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {templateImages?.map((file, index) => (
                <tr key={index}>
                  <td style={{ width: "10%" }}>{index + 1}</td>
                  <td style={{ width: "65%" }}>
                    <Image
                      onClick={() => openModal(file.original)}
                      className="theme-image-preview"
                      src={file.original}
                      alt="Preview"
                      thumbnail
                    />
                  </td>
                  <td>
                    {/* <button className='action-button' onClick={() => handleDelete(file)}><i className="fa fa-trash-o" aria-hidden="true"></i></button> */}
                    <button
                      //onClick={(e) => removeTemplateImage(e, index)}
                      onClick={() => showDeletePopUpHanlder(file)}
                      className="action-button theme-image-preview-remove"
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* <Button
          className="my-3"
          variant="primary"
          onClick={() => showDeletePopUpHanlder()}
          disabled={currentImageIds.length <= 0 && true}
        >
          Apply
        </Button> */}
      </div>
      {/* </Modal.Body> */}
      {/* 
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="upload-button"
            onClick={handleImageSubmit}
            disabled={templateImageLoading && true}
          >
            {templateImageLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner
                  animation="border"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                  size="sm"
                  role="status"
                />
                <span className="mx-3">Loading...</span>
              </div>
            ) : (
              "Add"
            )}
          </Button>
        </Modal.Footer> */}
      {/* </Modal> */}
    </>
  );
}

export default ImageUploadModal;
