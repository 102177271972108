import React from "react"
import {connect} from "react-redux"
import {useEffect, useState} from "react"
import {Button, Table, Alert, Badge} from "react-bootstrap"
import * as _ from "lodash"
import Loader from "../common/Loader"
import ReactPaginate from "react-paginate"

function PorjectsList(props) {
  const {getProjects, projectError, projectLoading, projectsList} = props

  const [pages, setpages] = useState(0)
  const [limit, setlimit] = useState(10)
  // const [skip, setskip] = useState(0)
  const isAdminFeature = data => {
    let adminExists = false

    for (const obj of data) {
      if (obj.feature.category === "Admin") {
        adminExists = true
        break
      }
    }
    return adminExists
  }

  const isCustomFeature = data => {
    let customExists = false

    for (const obj of data) {
      if (obj.feature.category === "Custom") {
        customExists = true
        break
      }
    }
    return customExists
  }
  const getCountFromFrontend = () => {
    let counted = 0
    if (projectsList?.count > 0) {
      counted = projectsList?.count
      return counted
    }
  }
  console.log(projectsList.Array, "projectsList")
  const pageCount = Math.ceil(projectsList.count / limit)
  useEffect(() => {
    getProjects({
      skip: pages,
      limit,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  return (
    <>
      <div className="container">
        {projectsList?.list ? (
          <Table className="card-dummy" striped bordered hover>
            <thead>
              <tr>
                <th>S.no</th>
                <th>Project Name</th>
                <th>Template Name</th>
                <th>Admin Features</th>
                <th>Custom Features</th>
                <th>Add-On-Cost</th>
                <th>Total Cost</th>

                <th>Status</th>
                <th>Description</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {projectsList?.list?.map((item, index) => (
                <tr key={index}>
                  <td> {index + 1 + pages * limit}</td>
                  <td>{item.name}</td>
                  <td>{item.template?.name}</td>

                  <td>
                    {isAdminFeature(item.features) ? (
                      <>
                        {item?.features
                          ?.filter(item => item.feature.category === "Admin")
                          .map((item, index, array) => (
                            <span key={index}>
                              {item?.feature?.name}
                              {index !== array?.length - 1 ? ", " : ""}
                            </span>
                          ))}
                      </>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {isCustomFeature(item.features) ? (
                      <>
                        {item?.features
                          ?.filter(item => item.feature.category === "Custom")
                          .map((item, index, array) => (
                            <span key={index}>
                              {item?.feature?.name}
                              {index !== array?.length - 1 ? ", " : ""}
                            </span>
                          ))}
                      </>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>{item.totalFeaturesCost}</td>
                  <td>{item.totalProjectCost}</td>
                  <td>{item.status}</td>
                  <td>{item?.description}</td>
                  <td>{item.emailAddress}</td>
                  <td>{item.phoneNumber}</td>
                  {/* <td> */}
                  {/* <button
                  className="action-button"
                  onClick={() => handleEdit(item)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button
                  className="action-button"
                  onClick={() => handleDelete(item)}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </button> */}
                  {/* </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div> No Request </div>
        )}

        {projectLoading && <Loader />}
      </div>
      <div className="paginationallignment">
        {getCountFromFrontend() > 5 && (
          <div className="paginationsection">
            <div className="paginationtxtholer">
              <span className="noofpagetxt">
                Showing {pages + 1} From{" "}
                {Math.ceil(getCountFromFrontend() / limit)}
              </span>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={e => {
                getProjects({
                  skip: e.selected * limit,
                  limit,
                })
                setpages(e.selected)
              }}
              forcePage={pages}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const projectLoading = _.get(state.app, "getSubmitProjectLoading", false)
  const projectsList = _.get(state.app, "submitProject", [])
  const projectError = _.get(state.app, "getSubmitProjectError", undefined)

  return {projectError, projectLoading, projectsList}
}

const mapDispatchToProps = dispatch => ({
  getProjects: data =>
    dispatch({type: "getsubmittedProjectSagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(PorjectsList)
