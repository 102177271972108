import React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import InputField from "../input-field/InputField";
const ForgotPasswordForm = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(undefined);


  useEffect(() => {
  
  }, []);

  useEffect(() => {
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      if (password.length !== 0) {
        let data = {
          email: email.trim(),
          password,
        };
        props.callLogin(data);
      } else {
        setPasswordError("Please Enter the password");
      }
    } else {
      alert("Please enter valid email");
    }
  };

  return (
    <div>
      <div id="login">
        <Container>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="login-card">
                  <div className="heading">
                    <span>Forgot password</span>
                  </div>

                  {/* <img src={logo} className="brandlogo" /> */}
                  <Card.Body>
                    <Form 
                    // onSubmit={handleSubmit}
                    >
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          classname="form-field"
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                      <Row>
                        <Button
                          disabled={props.signInLoading}
                          className="send Normaltext"
                          type="submit"
                        >
                          {props.signInLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <Spinner
                                animation="border"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                                size="sm"
                                role="status"
                              />
                              <span className="mx-3">Loading...</span>
                            </div>
                          ) : (
                            "Search"
                          )}
                        </Button>
                        {/* <CustomButton
                          type="button"
                          name="Login"
                          filled={true}
                          primary={true}
                          classname="login-btn"
                          // onClick={onSubmit}
                        /> */}
                      </Row>
                      {/* <Row className="mt-2 mb-3">
                        <Col align="center">
                          <GoogleLogin
                            clientId="493644900586-hkio4j2g3228k7qu9qqbmhblelm5n3dj.apps.googleusercontent.com"
                            buttonText="LOGIN WITH GOOGLE"
                            cookiePolicy={"single_host_origin"}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            prompt={"consent"}
                            // isSignedIn={true}
                          />
                        </Col>
                      </Row> */}

                      {/* <Row>
                        <Col className="forgot-div">
                          <a href="/forgot-password" className="forgot-text">
                            Forgot Password
                          </a>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="redirect-text">
                          Don't have an account yet?{" "}
                          <Link to="/signup" className="login-signup">
                            Sign up here
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ tracker = {}, user = {} }) => {
  const signInLoading = _.get(user, "signInLoading", false);
  const screen = _.get(tracker, "startupscreen", "");
  const signInError = _.get(user, "signInError", false);

  return { signInLoading, screen, signInError };
};

const mapDispatchToProps = (dispatch) => ({
  callLogin: (data) => dispatch({ type: "userSignInCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
