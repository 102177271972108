import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subFeatureList: undefined,
  subFeatureLoading: false,
  subFeatureEroor: undefined,
  subFeatureUpdate:false,
  allSubFeatures:[],
}

const subFeatureSlice = createSlice({
  name: "subFeature",
  initialState,
  reducers: {
    reset: (state) => {
      state.subFeatureList = undefined;
      state.subFeatureLoading = false;
      state.subFeatureEroor = undefined;
      state.allSubFeatures =[];
    },
  
    listTrigger: (state, action) => {
        state.subFeatureLoading = true;
        state.allSubFeatures = undefined;
        state.subFeatureEroor = undefined;
    },
    listSuccess: (state, action) => {
        state.subFeatureLoading = false;
        state.allSubFeatures = action.payload;
        state.subFeatureEroor = undefined;
    },
    listFail: (state, action) => {
        state.subFeatureLoading = false;
        state.allSubFeatures = undefined;
        state.subFeatureEroor = action.payload;
    },

  createTrigger:(state, action) =>{
      state.subFeatureLoading = true;
  },
  createSuccess:(state, action) =>{
      state.subFeatureLoading = false;
      state.allSubFeatures = [...state.allSubFeatures, action.payload];
      state.subFeatureEroor = undefined;
  },
  createFail:(state, action) =>{
      state.subFeatureLoading = false;
      state.subFeatureEroor = action.payload;
  },

  updateTrigger: (state, action) => {
    state.subFeatureLoading = true;
    state.subFeatureEroor = undefined;
},
updateSuccess: (state, action) => {
    state.subFeatureLoading = false;
    state.subFeatureUpdate=true;
    const { id, data } = action.payload;
    state.allSubFeatures = state.allSubFeatures.map(feature => {
        if (feature.id === id) {
            return {...feature, ...data};
        }
        return feature;
    });
},
updateFail: (state, action) => {
    state.subFeatureLoading = false;
    state.subFeatureEroor = action.payload;
},

deleteTrigger: (state, action) => {
    state.subFeatureLoading = true;
    state.subFeatureEroor = undefined;
},
deleteSuccess: (state, action) => {
    state.subFeatureLoading = false;
    state.allSubFeatures = state.allSubFeatures.filter(feature => feature.id !== action.payload);
},
deleteFail: (state, action) => {
    state.subFeatureLoading = false;
    state.subFeatureEroor = action.payload;
},

  }
});

export default subFeatureSlice.reducer;
export const {
    listTrigger,
    listSuccess,
    listFail,
    createTrigger,
    createSuccess,
    createFail,
    updateTrigger,
    updateFail,
    updateSuccess,
    deleteTrigger,
    deleteFail,
    deleteSuccess,
    reset,
} = subFeatureSlice.actions;
