import React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import InputField from "../input-field/InputField";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { SuccessPopUp } from "../popUp/PopUpCards";

const SignUpForm = ({
  callRegister,
  signUpLoading,
  signedUp,
  auth,
}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [roleType, setRole] = useState("Admin");
  const [loginType, setLoginType] = useState("email");
  const [showeye, setshoweye] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const [redirectionParam, setMyRedirection] = useState("");
  const [errorMail, setErrorMail] = useState(null);
  const [errorPass, setErrorPass] = useState(null);
  const [errConfirmPass, setErrorConfirmPass] = useState(null);
  const [signUpError, setSignUpError] = useState(null)
  const [signUpSuccesseMessage, setSignupSuccessMessage] = useState(
    "Your account has been created, Please login."
  );
  const [successPopUpShow, setSuccessPopUpShow] = useState(false);

  const successPopupHide = () => setSuccessPopUpShow(false);

  useEffect(() => {
    if (params !== undefined && params.has("name")) {
      setMyRedirection(params.get("name"));
    }
  }, [params]);

  // useEffect(() => {
  //   if (auth) {
  //     navigate("/");
  //   }
  // }, [auth]);

  const validateDate = () => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailRegex.test(email)) {
      setErrorMail("Please enter a valid email (example: example@example.com)");
      return;
    }
    if (password.length < 8) {
      setErrorPass("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setErrorConfirmPass("Passwords do not match.");
      return;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSignUpError("")
    if (validateDate()) {
      let data = {
        email: email.trim(),
        password,
        setSignUpError,
        role: "Admin",
        loginType,
        sendVerification: false,
        setSuccessPopUpShow,
        resetState,

        navigate,
      };
      callRegister(data);
    }
  };

  let resetState = () => {
    setEmail("");
    setPassword("");
    setErrorConfirmPass("")
    setErrorMail("")
    setErrorPass("")
    setConfirmPassword("")
    setSignUpError("")
  };

  return (
    <div>
      <SuccessPopUp
        message={signUpSuccesseMessage}
        show={successPopUpShow}
        signup={true}
        status={"succes"}
        button_text={"Close"}
        handleClose={successPopupHide}
        navigate = {navigate}
      />
      <div id="login">
        {/* {signUpLoading && <Loader />} */}
        <Container>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="login-card">
                  <div className="heading">
                    <span>Sign Up</span>
                  </div>

                  {/* <img src={logo} className="brandlogo" /> */}
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          classname="form-field"
                          onChange={(event) => {
                            setErrorMail(null);
                            setEmail(event.target.value);
                          }}
                        />
                        {errorMail && (
                          <div className="text-danger">{errorMail} </div>
                        )}
                      </FormGroup>

                      {/* <Form.Group cclassName="form-input">
                        <Form.Label>Login Type</Form.Label>
                        <Form.Control
                         onChange={(event) => {
                          setLoginType(event.target.value);
                        }}

                         value={loginType} as="select">
                            <option>email</option>
                            <option>mobile</option>
                            </Form.Control>
                        </Form.Group> */}
                      {/* <Form.Group cclassName="form-input">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                         onChange={(event) => {
                          setRole(event.target.value);
                        }}
                         value={roleType}  as="select">
                            <option>Admin</option>
                            <option>User</option>
                            </Form.Control>
                        </Form.Group> */}
                      <FormGroup className="form-input">
                        <label>Password</label>
                        <InputField
                          value={password}
                          type={showeye ? "password" : "text"}
                          classname="form-field"
                          onChange={(event) => {
                            setErrorPass(null);
                            setPassword(event.target.value);
                          }}
                          placeholder="Enter password"
                          eye
                          setshow={() => setshoweye(!showeye)}
                          showeye={showeye}
                        />
                        {errorPass && (
                          <div className="text-danger">{errorPass} </div>
                        )}
                      </FormGroup>

                      <FormGroup className="form-input">
                        <label>Confirm Password</label>
                        <InputField
                          value={confirmPassword}
                          type={showeye ? "password" : "text"}
                          classname="form-field"
                          onChange={(event) => {
                            setErrorConfirmPass(null);
                            setConfirmPassword(event.target.value);
                          }}
                          placeholder="Enter confirm password"
                          eye
                          setshow={() => setshoweye(!showeye)}
                          showeye={showeye}
                        />
                        {errConfirmPass && (
                          <div className="text-danger">{errConfirmPass} </div>
                        )}
                      </FormGroup>
                      {signUpError && (
                          <div className="text-danger">{signUpError} </div>
                        )}
                      <Row>
                        <Button
                          disabled={signUpLoading}
                          className="send Normaltext"
                          type="submit"
                        >
                          {signUpLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <Spinner
                                animation="border"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                                size="sm"
                                role="status"
                              />
                              <span className="mx-3">Loading...</span>
                            </div>
                          ) : (
                            "Sign Up"
                          )}
                        </Button>
                        {/* <CustomButton
                          type="button"
                          name="Login"
                          filled={true}
                          primary={true}
                          classname="login-btn"
                          // onClick={onSubmit}
                        /> */}
                      </Row>
                      {/* <Row className="mt-2 mb-3">
                        <Col align="center">
                          <GoogleLogin
                            clientId="493644900586-hkio4j2g3228k7qu9qqbmhblelm5n3dj.apps.googleusercontent.com"
                            buttonText="LOGIN WITH GOOGLE"
                            cookiePolicy={"single_host_origin"}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            prompt={"consent"}
                            // isSignedIn={true}
                          />
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="redirect-text">
                          Already have an account yet?{" "}
                          <Link to="/login" className="login-signup">
                            Login here
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const signUpLoading = _.get(user, "signUpLoading", false);
  const signedUp = _.get(user, "signedUp");
  const auth = _.get(user, "auth", false);

  return { signUpLoading, signedUp, auth };
};

const mapDispatchToProps = (dispatch) => ({
  callRegister: (data) => dispatch({ type: "userSignupcalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
