import React from "react";
import {
  Container,
  Row,
  Col,
 
} from "react-bootstrap";
 
class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
      errorMessage: "",
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true.valueOf, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // this.props.history.push('/error')
    // Catch errors in any components below and re-render with error message
    //       logErrorToExampleService(error, errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
       return (
        <div>
           <Container className={`paddingNav`} style={{ minheight: "50vh" }}>
            <Col>
              <h2>Something went wrong.</h2>
              <details style={{ whiteSpace: "pre-wrap" }}>
                <h5>{this.state.errorMessage}</h5>
                <br />
              </details>
            </Col>
          </Container>
         </div>
      );
    }
    if (!this.state.hasError) {
       return this.props.children;
    }
  }
}
export default ErrorBoundary;
